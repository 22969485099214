import { createStore } from 'vuex';

import auth from './modules/auth';
import notifications from './modules/notifications';
import menus from './modules/menus';
import contacts from './modules/contacts';
import projects from './modules/projects';
import templates from './modules/templates';
import fields from './modules/fields';
import tasks from './modules/tasks';
import images from './modules/images';
import offertes from './modules/offertes';

export default createStore({
  modules: {
    auth,
    notifications,
    menus,
    contacts,
    projects,
    templates,
    fields,
    tasks,
    images,
    offertes,
  },
});
