export default {
  methods: {
    resize_tabs() {
      const tabs = document.getElementById('tabs');
      const main = document.getElementsByTagName('main')[0];
      if (!tabs || !main) return false;
      tabs.style.width = `${main.offsetWidth}px`;
      return true;
    },
    resize_tab_listener() {
      window.addEventListener('resize', () => {
        this.resize_tabs();
      });
    },
  },
  created() {
    this.resize_tab_listener();
  },
  mounted() {
    this.resize_tabs();
  },
};
