<template>
  <div
    id="header"
    v-if="!/\/auth\/(login|register|reset-password)/.test($route.path)"
    class="print:hidden hidden fixed top-0 w-full z-20 flex-shrink-0  h-16 bg-background-dark border-b border-gray-300 "
  >
    <!-- Sidebar toggle, controls the 'sidebarOpen' sidebar state. -->
    <button
      @click.stop="menus_toggle('mobile_menu')"
      type="button"
      class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden"
    >
      <span class="sr-only">Open sidebar</span>
      <MenuAlt1Icon
        aria-hidden="true"
        class="h-6 w-6"
      />
    </button>
    <div class="flex-1 flex justify-between px-4 sm:px-6 lg:px-8">
      <div class="flex items-center">
        <!-- Profile dropdown -->
        <div class="ml-3 relative">
          <div>
            <button
              @click.stop="menus_toggle('mobile_user')"
              type="button"
              class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
              id="user-menu-button"
              aria-expanded="false"
              aria-haspopup="true"
            >
              <span class="sr-only">Open user menu</span>
              <img
                class="h-8 w-8 rounded-full"
                :src="user.avatar"
                alt=""
              >
            </button>
          </div>

          <!--
              Dropdown menu, show/hide based on menu state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
          <div
            v-if="menus.mobile_user"
            class="origin-top-left absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
            tabindex="-1"
          >
            <div
              class="py-1"
              role="none"
            >
              <!-- Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" -->
              <router-link
                to="/profile"
                class="text-gray-700 block px-4 py-2 text-sm"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-0"
              >View profile</router-link>
              <router-link
                to="/timings"
                class="text-gray-700 block px-4 py-2 text-sm"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-1"
              >My timings</router-link>
              <router-link
                to="/settings"
                class="text-gray-700 block px-4 py-2 text-sm"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-2"
              >Settings</router-link>
            </div>
            <div
              class="py-1"
              role="none"
            >
              <button
                @click.stop="deauth()"
                class="text-gray-700 block px-4 py-2 text-sm"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-5"
              >Logout</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import { MenuAlt1Icon } from '@heroicons/vue/outline';

export default {
  methods: {
    ...mapActions(['logout', 'menus_toggle']),
    async deauth() {
      await this.logout();
      document.location.reload();
    },
  },
  components: { MenuAlt1Icon },
  computed: mapGetters(['user', 'menus']),
};
</script>
