<template>
  <div>
    <!-- HIDDEN FILE INPUT -->
    <input
      @blur="$emit('blur')"
      @focus="$emit('focus')"
      @change="uploadFile($event)"
      type="file"
      class="hidden"
      :ref="name"
      :name="name"
    />

    <!-- LABEL -->
    <label
      v-if="label"
      :for="name"
      class="block text-sm font-medium text-gray-700"
    >{{ label }}</label>
    <div class="mt-1 relative rounded-md cursor-pointer">
      <!-- IMAGE PREVIEW -->
      <div v-if="modelValue && modelValue.slug ">
        <img
          class="h-32 w-32 object-cover peer"
          @click="$refs[name].click()"
          :src="fileUrl"
          alt=""
        >
      </div>
      <!-- UPLOAD INPUT -->

      <!-- class="pl-10 hidden w-full pr-10 focus:outline-none sm:text-sm rounded-md text-black" -->
      <div
        v-else
        @click="$refs[name].click()"
        class="flex items-center shadow-sm space-x-2 px-2 h-10 border-gray-300 border rounded-md text-gray-400"
        :class="{
          'border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500 ':
            error,
          'focus:ring-indigo-500 focus:border-indigo-500': !error,
        }"
        :aria-invalid="!!error"
        :aria-describedby="`${name}-error`"
      >
        <PhotographIcon class="w-5 h-5 " />
        <span>
          Select a file
        </span>
      </div>
      <div
        v-if="error"
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      >
        <ExclamationCircleIcon class="h-5 w-5 text-red-500" />
      </div>
    </div>
    <p
      v-if="!error && hint"
      class="mt-2 text-sm text-gray-500"
      id="email-description"
    >
      {{ hint }}
    </p>
    <p
      v-if="error"
      class="mt-2 text-sm text-red-600"
      :id="`${name}-error`"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
import Api from '@siliconminds/api';
import { ExclamationCircleIcon } from '@heroicons/vue/solid';
import { PhotographIcon } from '@heroicons/vue/outline';

const api = new Api(process.env.VUE_APP_API_URL);

export default {
  components: { ExclamationCircleIcon, PhotographIcon },
  props: {
    modelValue: [String, Object],
    name: { type: String, required: true },
    label: { type: String, default: '' },
    error: { type: String, default: '' },
    hint: { type: String, default: '' },
  },
  computed: {
    fileUrl() {
      return `${process.env.VUE_APP_API_URL}/${this.modelValue.slug}`;
    },
  },
  methods: {
    async uploadFile() {
      const formData = new FormData();
      formData.append('files', this.$refs[this.name].files[0]);
      const headers = { 'Content-Type': 'multipart/form-data' };
      const res = await api.post('images', formData, { headers });
      if (!res.success) {
        return this.$store.dispatch(
          'pushNotification',
          { type: 'error', title: 'Could not upload image' },
          { root: true },
        );
      }

      if (res.success) {
        this.$store.dispatch(
          'pushNotification',
          { type: 'success', title: 'Image uploaded!' },
          { root: true },
        );
        this.$emit('update:modelValue', res.data);
      }
    },
  },
};
</script>
