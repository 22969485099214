import envalidate from '@siliconminds/envalidate';
import Api from '@siliconminds/api';
import { required } from '@siliconminds/validation/validators';

envalidate('VUE_APP_API_URL');

const api = new Api(process.env.VUE_APP_API_URL);

const endpoint = 'tasks';

const locations = [
  { key: 'office', label: 'Office' },
  { key: 'atelier', label: 'Atelier' },
  { key: 'onsite', label: 'Onsite' },
];

const states = [
  { key: 'todo', label: 'To Do' },
  { key: 'doing', label: 'Doing' },
  { key: 'done', label: 'Done' },
  { key: 'blocked', label: 'Blocked', disabled: true },
];

export default {
  namespaced: true,
  state: {
    visibleFields: [],
    query: {
      q: '',
      deadline: 'any',
      blocked: 'any',
      locations: [],
      states: [],
      projects: [],
    },

    pagination: {
      limit: 25,
      prev: false,
      page: 1,
      next: false,
      last: false,
    },

    fields: [
      {
        label: 'Name',
        key: 'name',
        type: 'text',
        editable: true,
        validators: { required },
      },
      {
        label: 'Dynamic Name',
        key: 'dynamicName',
        type: 'text',
        editable: false,
        validators: {},
      },
      {
        label: 'Desc',
        key: 'desc',
        type: 'textarea',
        editable: true,
        validators: { required },
      },
      {
        label: 'Dynamic Desc',
        key: 'dynamicDesc',
        type: 'textarea',
        editable: false,
        validators: {},
      },

      {
        label: 'Notes',
        key: 'notes',
        type: 'textarea',
        editable: true,
        validators: {},
      },

      {
        label: 'State',
        key: 'state',
        type: 'select',
        options: states,
        optionKey: 'key',
        optionLabel: 'label',
        editable: true,
        validators: { required },
      },
      {
        label: 'Priority',
        key: 'priority',
        type: 'text',
        editable: false,
        validators: {},
      },

      {
        label: 'Location',
        key: 'location',
        type: 'select',
        options: locations,
        optionKey: 'key',
        optionLabel: 'label',
        editable: true,
        validators: { required },
      },
      {
        label: 'Project',
        key: 'project',
        type: 'select',
        options: [],
        optionKey: '_id',
        optionLabel: 'name',
        editable: true,
        validators: { required },
      },
      {
        label: 'Blocked by',
        key: 'blockedBy',
        type: 'select',
        options: [],
        optionKey: '_id',
        optionLabel: 'dynamicName',
        editable: true,
        single: false,
        validators: {},
      },
      {
        label: 'Deadline',
        key: 'deadline',
        type: 'date',
        editable: true,
        validators: { required },
      },
    ],

    all: [],
    single: {
      blockedBy: [],
      location: '',
      project: '',
      state: '',
      comments: [],
    },
    active: false,
  },
  getters: {
    fields: (state) => state.fields,

    all: (state) => state.all,
    single: (state) => state.single,
    active: (state) => state.active,

    locations: (state) => state.fields.find(el => el.key === 'location').options,
    // states: (state) => state.fields.find(el => el.key === 'state').options,
    states: () => states,

    visibleFields: (state) => state.visibleFields,
    query: (state) => state.query,

    pagination: (state) => state.pagination,
  },
  actions: {
    set_query({ commit }, query) {
      commit('setQuery', query);
    },
    set_visibleFields({ commit }, visibleFields) {
      commit('setVisibleFields', visibleFields);
    },
    async set_page({ dispatch, commit, getters }, page) {
      const { pagination } = getters;
      if ((page < 0 && pagination.page > 1) || (page > 0 && pagination.page < pagination.last)) {
        commit('setPage', page);
      }
      dispatch('get_all');
    },

    // sort tasks
    sort({ commit, state }, sort) {
      const priorities = ['low', 'normal', 'high', 'critical'];
      const states = ['blocked', 'todo', 'doing', 'done'];
      const tasks = state.all;
      let sorted;
      switch (sort.by) {
        case 'deadline':
          sorted = tasks.sort((a, b) => new Date(a.deadline).getTime() - new Date(b.deadline).getTime());
          break;
        case 'project':
          sorted = tasks.sort((a, b) => {
            if (a.project === b.project) return 0;
            return 1;
          });
          break;
        case 'state':
          sorted = tasks.sort((a, b) => states.indexOf(a.state) - states.indexOf(b.state));
          break;
        case 'priority':
          console.log('sort by prio');
          sorted = tasks.sort((a, b) => priorities.indexOf(a.priority) - priorities.indexOf(b.priority));
          break;
        case 'location':
          sorted = tasks.sort((a, b) => a.location.normalize().localeCompare(b.location.normalize()));
          break;
        default: break;
      }
      if (sort.order === 'dsc') sorted = sorted.reverse();
      commit('setAll', sorted);
    },
    // Active task management
    async get_active({ commit, dispatch }) {
      const res = await api.get(`${endpoint}/active`);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      commit('setActive', res.data);
    },
    async start({ commit, dispatch }, task) {
      const res = await api.put(`${endpoint}/active`, task);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      dispatch('pushNotification', { type: 'success', title: 'Task started!' }, { root: true });
      return commit('setActive', task);
    },
    async end({ commit, dispatch }) {
      const res = await api.delete(`${endpoint}/active`);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      dispatch('pushNotification', { type: 'success', title: 'Task ended!' }, { root: true });
      return commit('setActive', false);
    },

    async get({ commit, getters, dispatch }, payload = {}) {
      const { pagination, query } = getters;
      const res = await api.get(endpoint, {
        query: {
          ...(payload || query),
          _page: pagination.page,
          _limit: pagination.limit,
        },
      });
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      commit('setPagination', res.pagination);
      return commit('setAll', res.data);
    },
    async get_all({ commit, getters, dispatch }, payload = {}) {
      const { pagination } = getters;
      const res = await api.get(endpoint, {
        query: {
          ...payload,
          _page: pagination.page,
          _limit: pagination.limit,
        },
      });
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      commit('setPagination', res.pagination);
      return commit('setAll', res.data);
    },
    async get_single({ commit, dispatch }, id) {
      const res = await api.get(`${endpoint}/${id}`);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      return commit('setSingle', res.data);
    },
    async post({ commit, dispatch }, payload) {
      const res = await api.post(endpoint, payload);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      dispatch('pushNotification', { type: 'success', title: 'Task created!' }, { root: true });
      commit('push', res.data);
      return res.data;
    },
    async post_comment({ commit, dispatch, state }, content) {
      console.log('add comment', content, `${endpoint}/${state.single._id}/comments`);
      const res = await api.post(`${endpoint}/${state.single._id}/comments`, { content });
      console.log('posted comment', content);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      dispatch('pushNotification', { type: 'success', title: 'Comment created!' }, { root: true });
      return commit('pushComment', res.data);
    },
    async post_image({ commit, dispatch, state }, image) {
      const formData = new FormData();
      formData.append('files', image);
      const headers = { 'Content-Type': 'multipart/form-data' };
      const res = await api.post(`${endpoint}/${state.single._id}/images`, formData, { headers });
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      commit('pushFile', res.data);
      dispatch('pushNotification', { type: 'success', title: 'Image uploaded' }, { root: true });
    },
    async post_file({ commit, dispatch, state }, file) {
      const formData = new FormData();
      formData.append('files', file);
      const headers = { 'Content-Type': 'multipart/form-data' };
      const res = await api.post(`${endpoint}/${state.single._id}/files`, formData, { headers });
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      commit('pushFile', res.data);
      dispatch('pushNotification', { type: 'success', title: 'File uploaded' }, { root: true });
    },

    async put({ commit, dispatch }, payload) {
      const res = await api.put(`${endpoint}/${payload._id}`, payload);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      dispatch('pushNotification', { type: 'success', title: 'Task saved!' }, { root: true });
      return commit('update', payload);
    },
    async sync({ dispatch }, payload) {
      const res = await api.put(`${endpoint}/${payload._id}/sync`, payload);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      dispatch('pushNotification', { type: 'success', title: 'Task synced!' }, { root: true });
      return true;
    },
    async delete({ commit, getters, state, dispatch }, id) {
      const res = await api.delete(`${endpoint}/${id}`);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      // end the task if it is currently active
      if (state.active && state.active._id === id) await dispatch('end');
      commit('delete', id);
      dispatch('pushNotification', { type: 'success', title: 'Task archived!' }, { root: true });
      if (getters.pagination.page > 1 && getters.all.length < 1) return dispatch('set_page', -1);
      return true;
    },
    async delete_image({ commit, state, dispatch }, imageId) {
      const res = await api.delete(`${endpoint}/${state.single._id}/images/${imageId}`);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      commit('deleteFile', imageId);
      dispatch('pushNotification', { type: 'success', title: 'Image removed!' }, { root: true });
      return true;
    },

  },
  mutations: {
    setAll: (state, items) => { state.all = items; },
    setSingle: (state, item) => { state.single = item; },
    push: (state, item) => { state.all.push(item); },
    update: (state, item) => { state.all[state.all.findIndex((el) => el._id.toString() === item._id.toString())] = item; },
    delete: (state, id) => { state.all = state.all.filter(el => el._id.toString() !== id.toString()); },

    setActive: (state, task) => { state.active = task; },

    pushComment: (state, item) => { state.single.comments.push(item); },

    pushFile: (state, item) => { state.single.files.push(item); },
    deleteFile: (state, id) => { state.single.files = state.single.files.filter(el => el._id.toString() !== id.toString()); },

    setVisibleFields: (state, item) => { state.visibleFields = item; },
    setQuery: (state, item) => { state.query = item; },

    setPagination: (state, pagination) => { state.pagination = pagination; },
    setPage: (state, page) => { state.pagination.page += page; },
  },
};
