<template>
  <div>
    <label
      :for="name"
      class="block text-sm font-medium text-gray-700"
    >{{label}}</label>
    <div class="mt-1 relative rounded-md shadow-sm">
      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <!-- Heroicon name: solid/mail -->
        <SearchIcon class="h-5 w-5 text-gray-400" />
      </div>
      <input
        type="text"
        :name="name"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        class="bg-background focus:ring-primary focus:border-primary block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
        placeholder="Search"
      >
    </div>
  </div>

</template>

<script>
import { SearchIcon } from '@heroicons/vue/outline';

export default {
  props: {
    label: String,
    name: String,
    modelValue: String,
  },
  components: {
    SearchIcon,
  },
};
</script>
