<template>
  <div class="bg-background-light ">
    <PageHeading title="Image">
      <!-- <BaseButton
        type="danger"
        @click="removeImage()"
      >
        Remove Image
      </BaseButton> -->
    </PageHeading>

    <div class="relative">
      <section class="absolute -top-6 left-0 right-0 lg:static lg:px-6">
        <img
          id="image"
          class="object-contain object-center mx-auto mb-6"
          :src="imageUrl"
        />
        <!--
        <div
          id=""
          class="invisible absolute bottom-0 flex justify-end w-full bg-gray-200 bg-opacity-80 p-2"
        >
          <TrashIcon
            @click="removeImage(image)"
            class="w-6 h-6 text-gray-600 hover:text-black"
          />

        </div> -->

      </section>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import { TrashIcon } from '@heroicons/vue/outline';
import PageHeading from '@/components/PageHeading.vue';

export default {
  name: 'ProjectsEditImages',
  data: () => ({}),
  components: {
    PageHeading,
    // TrashIcon,
  },
  computed: {
    ...mapGetters({
      image: 'images/single',
      project: 'projects/single',
    }),
    imageUrl() {
      return `${process.env.VUE_APP_API_URL}/${this.$store.state.images.single.slug}`;
    },
  },
  methods: {
    ...mapActions({
      get_image: 'images/get_single',
      delete_image: 'images/image',
    }),
    async removeImage(image) {
      await this.delete_image(image._id);
    },
  },
  async mounted() {
    await this.get_image(this.$route.params.imageId);
  },
};
</script>

<style scoped>
#image {
  max-height: 90vh;
}
</style>
