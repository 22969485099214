<template>
  <div
    :class="disabled ? 'opacity-50' : '' "
    v-click-outside="onClickOutside"
  >
    <label
      id="listbox-label"
      class=" block text-sm font-medium text-gray-700"
    >
      {{ label }}
    </label>
    <div class="mt-1 relative">
      <button
        @click="toggle()"
        type="button"
        class="relative w-full bg-background border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary sm:text-sm"
        :class="{
          'border-danger text-danger placeholder-danger focus:ring-danger focus:border-danger':
            error,
          'focus:ring-primary focus:border-primary': !error,
        }"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
      >
        <span
          class="block truncate"
          v-if="single && options[selected]"
        >
          {{options[selected][optionLabel]}}
        </span>
        <span
          class="block truncate"
          v-else-if="!single && selected.length > 0"
        >
          {{options[selected[0]][optionLabel]}}
        </span>

        <span
          class="text-gray-600 block truncate"
          v-else
        >
          <span v-if="single">Pick an option</span>
          <span v-else>Select options</span>

        </span>

        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <!-- Heroicon name: solid/selector -->
          <SelectorIcon
            class="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </button>

      <!--
      Select popover, show/hide based on select state.

      Entering: ""
        From: ""
        To: ""
      Leaving: "transition ease-in duration-100"
        From: "opacity-100"
        To: "opacity-0"
    -->
      <ul
        v-if="open"
        class="absolute z-10 mt-1 w-full bg-background shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
        tabindex="-1"
        role="listbox"
        aria-labelledby="listbox-label"
        aria-activedescendant="listbox-option-3"
      >
        <li
          v-for="(option, index) in options"
          :key="index"
          @click="select(index)"
          class="cursor-default select-none relative py-2 pl-8 pr-4"
          :class="{ 'text-white bg-primary': index == highlighted, 'text-gray-900': index != highlighted }"
          @mouseenter="highlighted = index"
          @mouseleave="highlighted = -1"
          id="listbox-option-0"
          role="option"
        >
          <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
          <span
            class="font-normal block truncate"
            :class="{
              'font-semibold': single && selected == index || !single && selected.includes(index),
              'opacity-50': option.disabled
            }"
          >
            {{ option[optionLabel] }}
          </span>

          <!--
          Checkmark, only display for selected option.
          Highlighted: "text-white", Not Highlighted: "text-indigo-600"
        -->
          <span
            v-if="single && selected == index || !single && selected.includes(index)"
            class="absolute inset-y-0 left-0 flex items-center pl-1.5"
            :class="{ 'text-white': index == highlighted, 'text-primary': index != highlighted }"
          >
            <!-- Heroicon name: solid/check -->
            <CheckIcon
              class="h-5 w-5"
              aria-hidden="true"
            />
          </span>
        </li>

      </ul>
    </div>
    <p
      v-if="!error && hint"
      class="mt-2 text-sm text-gray-500"
      id="email-description"
    >
      {{ hint }}
    </p>
    <p
      v-if="error"
      class="mt-2 text-sm text-red-600"
      :id="`${name}-error`"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid';
import vClickOutside from 'click-outside-vue3';

export default {
  components: {
    CheckIcon,
    SelectorIcon,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    modelValue: [String, Number, Array],
    name: { type: String, required: true },
    label: { type: String, required: true },
    placeholder: { type: String, default: '' },
    error: { type: String, default: '' },
    hint: { type: String, default: '' },
    options: { type: Array, required: true },
    optionKey: { type: String, required: true },
    optionLabel: { type: String, required: true },
    single: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
  },
  data: () => ({
    selected: [],
    highlighted: -1,
    open: false,
  }),
  methods: {
    select(index) {
      if (this.options[index].disabled) return;
      if (this.single) {
        this.selected = index;
        this.$emit('update:modelValue', this.options[index][this.optionKey]);
        this.open = false;
      } else {
        if (!this.selected.includes(index)) {
          this.selected.push(index);
        } else this.selected = this.selected.filter((el) => el !== index);

        this.$emit(
          'update:modelValue',
          this.selected.map((index) => this.options[index][this.optionKey]),
        );
      }
    },
    onClickOutside() {
      this.open = false;
    },
    toggle() {
      if (this.disabled) return;
      this.open = !this.open;
    },
    loadSelected() {
      if (this.single) {
        this.selected = -1;
        this.selected = this.options.findIndex(
          (el) => el[this.optionKey] === this.modelValue,
        );
      } else {
        this.selected = [];
        this.modelValue.forEach((value) => {
          const index = this.options.findIndex(
            (option) => option[this.optionKey] === value,
          );
          if (index >= 0) this.selected.push(index);
        });
      }
      this.$forceUpdate();
    },
  },
  watch: {
    modelValue() {
      this.loadSelected();
    },
    options() {
      this.loadSelected();
    },
  },
  mounted() {
    this.loadSelected();
  },
};
</script>
