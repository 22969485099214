<template>
  <div class="bg-background-light ">
    <PageHeading title="Tasks">
      <div class="space-x-2">
        <BaseButton @click="toggle('sort')">
          <span class="hidden sm:block">Sort tasks</span>
          <SortDescendingIcon class="sm:hidden w-5 h-5" />
        </BaseButton>
        <BaseButton @click="toggle('filter')">
          <span class="hidden sm:block">Filter tasks</span>
          <FilterIcon class="sm:hidden w-5 h-5" />
        </BaseButton>
        <BaseButton @click="createTask()">
          <span class="hidden sm:block">New task</span>
          <PlusIcon class="sm:hidden w-5 h-5" />
        </BaseButton>
      </div>
    </PageHeading>

    <!-- Query -->
    <section
      v-if="showFilter"
      class="relative top-9 py-4 px-4 sm:px-6 bg-background-dark"
    >
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 xl:flex xl:items-end w-full">
        <Search
          class="w-full"
          v-model="query.q"
          label="Search"
          name="search"
        />
        <BaseSelect
          class="w-full"
          :options="locations"
          v-model="query.locations"
          optionKey="key"
          optionLabel="label"
          :single="false"
          label="Location"
          name="location"
        />
        <BaseSelect
          class="w-full"
          :options="states"
          v-model="query.states"
          optionKey="key"
          optionLabel="label"
          :single="false"
          label="State"
          name="state"
        />
        <BaseSelect
          class="w-full"
          :options="projects"
          v-model="query.projects"
          optionKey="_id"
          optionLabel="name"
          :single="false"
          label="Project"
          name="project"
        />
        <BaseSelect
          class="w-full"
          :options="[
            { key: 'any', label: 'Any'},
            { key: 'false', label: 'No'},
            { key: 'true', label: 'Yes'}
          ]"
          optionKey="key"
          optionLabel="label"
          v-model="query.blocked"
          label="Blocked"
          name="blocked"
        />
        <BaseSelect
          class="w-full"
          :options="[
            { key: 'any', label: 'Any'},
            { key: 'week', label: 'This week'},
            { key: 'month', label: 'This month'},
            { key: 'year', label: 'This year'},
          ]"
          optionKey="key"
          optionLabel="label"
          v-model="query.deadline"
          label="Deadline"
          name="deadline"
        />
      </div>
    </section>

    <!-- Sort -->
    <section
      v-if="showSort"
      class="relative top-9 py-4 px-4 sm:px-6 bg-background-dark"
    >
      <BaseSelect
        class="w-full"
        v-model="sort.by"
        :options="[
          {key: 'deadline', label:'Deadline'},
          {key: 'priority', label:'Priority'},
          {key: 'project', label:'Project'},
          {key: 'state', label:'State'},
          {key: 'location', label:'Location'},
        ]"
        optionKey="key"
        optionLabel="label"
        :single="true"
        label="Sort by"
        name="sort-by"
      />
      <BaseSelect
        class="w-full"
        v-model="sort.order"
        :options="[
          {key: 'asc', label:'Ascending'},
          {key: 'dsc', label:'Descending'},
        ]"
        optionKey="key"
        optionLabel="label"
        :single="true"
        label="Order"
        name="sort-order"
      />
    </section>

    <!-- Tabs -->
    <section
      id="tabs"
      class="fixed top-16 z-10 bg-background-dark"
    >
      <div class="block">
        <div class="border-b border-gray-200">
          <nav
            class="-mb-px flex"
            aria-label="Tabs"
          >
            <router-link
              :to="`/tasks/list`"
              class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-1/3 py-4 px-1 text-center border-b-2 font-medium text-sm"
              :class="{ 'border-primary text-primary': /tasks\/list/.test($route.path)}"
            >
              List
            </router-link>
            <router-link
              :to="`/tasks/table`"
              class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-1/3 py-4 px-1 text-center border-b-2 font-medium text-sm"
              :class="{ 'border-primary text-primary': /tasks\/table/.test($route.path)}"
            >
              Table
            </router-link>

            <router-link
              :to="`/tasks/kanban`"
              class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-1/3 py-4 px-1 text-center border-b-2 font-medium text-sm"
              :class="{ 'border-primary text-primary': /tasks\/kanban/.test($route.path)}"
            >
              Kanban
            </router-link>
            <router-link
              :to="`/tasks/calendar`"
              class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-1/3 py-4 px-1 text-center border-b-2 font-medium text-sm"
              :class="{ 'border-primary text-primary': /tasks\/calendar/.test($route.path)}"
            >
              Calendar
            </router-link>

          </nav>
        </div>
      </div>
    </section>

    <router-view
      @open_task="$refs.task.open($event)"
      @create_task="createTask()"
      class="relative top-10"
    ></router-view>

    <!-- TASK POPUP -->
    <TaskModal ref="task" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  PlusIcon,
  FilterIcon,
  SortDescendingIcon,
} from '@heroicons/vue/outline';
import PageHeading from '@/components/PageHeading.vue';
import Search from '@/components/Search.vue';
import TaskModal from '@/components/TaskModal.vue';

export default {
  name: 'tasksEdit',
  data: () => ({
    showFilter: false,
    showSort: false,
    sort: {
      by: '',
      order: 'asc',
    },
  }),
  components: {
    PageHeading,
    Search,
    PlusIcon,
    FilterIcon,
    SortDescendingIcon,
    TaskModal,
  },
  computed: {
    ...mapGetters({
      user: 'user',
      locations: 'tasks/locations',
      projects: 'projects/all',
      states: 'tasks/states',
    }),
    query: {
      get() {
        return this.$store.state.tasks.query;
      },
      set(value) {
        // save to localstorage
        this.$store.commit('tasks/setQuery', value);
      },
    },
  },
  watch: {
    query: {
      deep: true,
      async handler() {
        window.localStorage.setItem('task_query', JSON.stringify(this.query));
        // get the tasks based on the query
        await this.get_tasks(this.query);
      },
    },
    sort: {
      deep: true,
      handler() {
        this.sort_tasks(this.sort);
      },
    },
  },
  methods: {
    ...mapActions({
      me: 'me',
      get_projects: 'projects/get_all',
      get_tasks: 'tasks/get',
      post_task: 'tasks/post',
      sort_tasks: 'tasks/sort',
    }),
    toggle(menu) {
      if (menu === 'filter') {
        this.showSort = false;
        this.showFilter = !this.showFilter;
      }
      if (menu === 'sort') {
        this.showFilter = false;
        this.showSort = !this.showSort;
      }
    },
    navigateTo(event) {
      this.$router.push(event.target.value);
    },
    async createTask() {
      const created = await this.post_task({
        name: 'name',
        desc: 'description',
      });
      this.$refs.task.open(created);
    },
  },
  async mounted() {
    console.log('mount tasks');
    if (!this.user) await this.me();
    // restrict access
    this.checkUserRoles('user');

    await this.get_projects({ archived: true });

    // load settings from localstorage
    const query = window.localStorage.getItem('task_query');
    if (query) this.query = JSON.parse(query);
    // await this.get_projects(this.query);
  },
};
</script>
