<template>
  <div>
    <BaseInput
      v-if="['text', 'email', 'number'].includes(field.type || field.dataType) "
      :type="field.type || field.dataType"
      @update:modelValue="$emit('update:modelValue', $event)"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
      :modelValue="modelValue"
      :name="field.key"
      :label="field.label"
      :error="error"
    />
    <BaseCheckbox
      v-if="(field.type || field.dataType) === 'checkbox'"
      @update:modelValue="$emit('update:modelValue', $event)"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
      :modelValue="modelValue"
      :name="field.key"
      :label="field.label"
      :error="error"
    />
    <BaseDate
      v-if="(field.type || field.dataType) === 'date'"
      @update:modelValue="$emit('update:modelValue', $event)"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
      :modelValue="modelValue"
      :name="field.key"
      :label="field.label"
      :error="error"
    />
    <BaseMoney
      v-if="(field.type || field.dataType) === 'money'"
      @update:modelValue="$emit('update:modelValue', $event)"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
      :modelValue="modelValue"
      :name="field.key"
      :label="field.label"
      :error="error"
    />
    <BaseUpload
      v-if="['file', 'image'].includes(field.type || field.dataType) "
      @update:modelValue="$emit('update:modelValue', $event)"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
      :modelValue="modelValue"
      :name="field.key"
      :label="field.label"
      :error="error"
    />
    <BaseTextArea
      v-if="(field.type || field.dataType) === 'textarea'"
      @update:modelValue="$emit('update:modelValue', $event)"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
      :modelValue="modelValue"
      :name="field.key"
      :label="field.label"
      :error="error"
    />

    <BaseSelect
      v-if="(field.type || field.dataType) === 'select'"
      @update:modelValue="$emit('update:modelValue', $event)"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
      :modelValue="modelValue"
      :label="field.label"
      :name="field.key"
      :options="field.options"
      :optionKey="field.optionKey"
      :optionLabel="field.optionLabel"
      :single="field.single"
      :error="error"
    />

    <BaseInputUnit
      v-if="['meter', 'gram', 'liter'].includes(field.type || field.dataType)"
      :type="field.type || field.dataType"
      @update:modelValue="$emit('update:modelValue', $event)"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
      :modelValue="modelValue"
      :name="field.key"
      :label="field.label"
      :error="error"
    />

    <div v-if="(field.type || field.dataType) === 'object'">
      <label
        :for="field.name"
        class="block text-md font-medium text-gray-700 mb-4 mt-8"
      >{{ field.label }}</label>

      <DynamicField
        v-for="subfield, index of field.fields"
        :key="index"
        :field="subfield"
        :modelValue="(modelValue || {})[subfield.key]"
        @update:modelValue="update(subfield.key, $event)"
      />
    </div>

    <div
      v-if="(field.type || field.dataType) === 'array'"
      class="space-y-4"
    >
      <label
        :for="field.name"
        class="block text-md font-medium text-gray-700 mb-4 mt-8"
      >{{ field.label }}</label>

      <pre>{{arrayModel}}</pre>

      <DynamicField
        :key="index"
        :field="field.content"
        :modelValue="arrayModel"
        @update:modelValue="arrayModel = $event"
      />

      <div class="flex justify-end">
        <BaseButton>add</BaseButton>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'dynamic_field',
  props: {
    field: {
      type: Object,
      required: true,
    },
    modelValue: {
      required: true,
    },
    error: String,
  },
  data: () => ({
    arrayModel: undefined,
  }),
  methods: {
    update(key, value) {
      console.log('update (field)', key, value);
      // deep copy the old object
      const newValue = { ...this.modelValue };
      // update the value
      newValue[key] = value;
      // emit the update
      this.$emit('update:modelValue', newValue);
    },
  },
};

/* fields: [{
  type: '',
  label: '',
  key: '',
  editable: true,
}] */
</script>
