<template>
  <div class="bg-background-light ">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'TemplatesEditTasks',
};
</script>
