<template>
  <div class="z-50 absolute inset-0 bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
    <div class="max-w-max mx-auto">
      <main class="sm:flex">
        <p class="text-4xl font-extrabold text-indigo-600 sm:text-5xl">{{$route.params.code}}</p>
        <div class="sm:ml-6">
          <div class="sm:border-l sm:border-gray-200 sm:pl-6">
            <h1 class="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
              {{title[$route.params.code] || 'Oops...'}}
            </h1>
            <p class="mt-1 text-base text-gray-500">{{body[$route.params.code] || 'Something unexpected happened. Try again later.'}}</p>
          </div>
          <div class="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
            <button
              @click="reauthenticate()"
              v-if="$route.params.code == 403"
              class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Log in as a diffrent user
            </button>
            <router-link
              v-else
              to="/"
              class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Go back home
            </router-link>

          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'error',
  data: () => ({
    title: {
      404: 'Page not found',
      403: 'Forbidden',
    },
    body: {
      404: 'Please check the URL in the address bar and try again.',
      403: 'Acces to this page is restricted.',
    },
  }),
  methods: {
    ...mapActions(['logout']),
    async reauthenticate() {
      await this.logout();
      this.$router.push('/auth/login');
    },
  },
  async mounted() {
    console.log('mounted error', this.$route.params.code);
  },
};
</script>
