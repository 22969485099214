<template>
  <div class="bg-background-light">

    <PageHeading title="Edit contact" />

    <TitleDesc
      class="base-container"
      title="Contact info"
      desc="Here you can edit the info for this contact."
    >
      <BaseButton
        type="danger"
        @click="popupConfirmDelete = true"
      >
        archive
      </BaseButton>
      <BaseButton
        class="sm:col-start-2 row-start-1"
        @click="submit()"
      >
        save
      </BaseButton>

    </TitleDesc>

    <section class="base-container base-card">
      <DynamicForm
        :fields="fields"
        v-model="contact"
        @submit="submit()"
      >
        <BaseButton
          type="danger"
          @click.prevent="popupConfirmDelete = true"
        >
          archive
        </BaseButton>
      </DynamicForm>
    </section>

    <BaseModal
      :open="popupConfirmDelete"
      @close="popupConfirmDelete = false"
      title="Archive this contact?"
    >
      <p class="text-gray-600 mb-2">Archived contacts can still be restored in the archive.</p>
      <div class="flex w-full justify-end space-x-2 pt-2">
        <BaseButton
          class="flex-1"
          @click.prevent="popupConfirmDelete = false"
        >
          cancel
        </BaseButton>
        <BaseButton
          class="flex-1"
          type="danger"
          @click.prevent="remove()"
        >
          archive
        </BaseButton>
      </div>
    </BaseModal>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageHeading from '@/components/PageHeading.vue';
import DynamicForm from '@/components/DynamicForm.vue';
import TitleDesc from '@/components/TitleDesc.vue';

export default {
  name: 'ContactsEdit',
  data: () => ({
    popupConfirmDelete: false,
  }),
  components: {
    TitleDesc,
    PageHeading,
    DynamicForm,
  },
  computed: {
    ...mapGetters({
      users: 'users',
      fields: 'contacts/fields',
    }),
    contact: {
      get() {
        return this.$store.state.contacts.single;
      },
      set(value) {
        this.$store.commit('contacts/setSingle', value);
      },
    },
  },
  methods: {
    ...mapActions({
      me: 'me',
      put_contact: 'contacts/put',
      get_contact: 'contacts/get_single',
      delete_contact: 'contacts/delete',
    }),
    async submit() {
      console.log('submit');
      await this.put_contact(this.contact);
    },
    async remove() {
      await this.delete_contact(this.contact);
      this.$router.push('/contacts');
    },
  },
  async mounted() {
    if (!this.user) await this.me();
    // restrict access
    this.checkUserRoles('user');
    console.log('mount');
    await this.get_contact(this.$route.params.contactId);
  },
};
</script>
