<template>
  <div>
    <label
      v-if="label"
      :for="name"
      class="block text-sm font-medium text-gray-700"
    >{{ label }}</label>

    <div class="mt-1 relative rounded-md shadow-sm">
      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <!-- Heroicon name: solid/mail -->
        <CurrencyEuroIcon class="w-5 h-5 text-gray-400" />
      </div>
      <input
        @blur="$emit('blur')"
        @focus="$emit('focus')"
        @input="$emit('update:modelValue', $event.target.value)"
        :value="modelValue"
        type="number"
        :name="name"
        :class="{
          'border-red-300 text-danger placeholder-danger focus:ring-danger focus:border-danger ':
            error,
          'focus:ring-primary focus:border-primary': !error,
        }"
        class="pl-10 bg-background border-gray-300 block w-full pr-10 focus:outline-none sm:text-sm rounded-md"
        min="0"
        :placeholder="placeholder"
        :aria-invalid="!!error"
        :aria-describedby="`${name}-error`"
      />
      <div
        v-if="error"
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      >
        <ExclamationCircleIcon class="h-5 w-5 text-danger" />
      </div>

    </div>
    <p
      v-if="!error && hint"
      class="mt-2 text-sm text-gray-500"
      id="email-description"
    >
      {{ hint }}
    </p>
    <p
      v-if="error"
      class="mt-2 text-sm text-danger"
      :id="`${name}-error`"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
import { ExclamationCircleIcon } from '@heroicons/vue/solid';
import { CurrencyEuroIcon } from '@heroicons/vue/outline';

export default {
  components: { ExclamationCircleIcon, CurrencyEuroIcon },
  props: {
    modelValue: { type: [Number, String], default: 0 },
    name: { type: String, required: true },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    error: { type: String, default: '' },
    hint: { type: String, default: '' },
  },
  computed: {
    value() {
      if (typeof this.modelValue !== 'object') {
        return this.modelValue;
      }
      return this.modelValue.toISOString().split('T')[0];
    },
  },
};
</script>
