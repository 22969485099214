<template>
  <div class="bg-background-light ">
    <!-- General settings -->

    <TitleDesc
      class="base-container"
      title="Basic settings"
      desc="Here you can edit the basic settings of your new project."
    >
      <BaseButton
        type="danger"
        @click="removeProject()"
      >archive</BaseButton>
      <BaseButton
        class="row-start-1 sm:col-start-2"
        type="primary"
        @click="save()"
      >Save</BaseButton>
    </TitleDesc>

    <section class="base-container base-card">
      <DynamicForm
        :fields="fields"
        v-model="project"
        @submit="save()"
      >
        <BaseButton
          type="danger"
          @click="removeProject()"
        >archive</BaseButton>
      </DynamicForm>

    </section>

    <ModalConfirm ref="confirmation" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DynamicForm from '@/components/DynamicForm.vue';
import TitleDesc from '@/components/TitleDesc.vue';
import ModalConfirm from '@/components/ModalConfirm.vue';

export default {
  name: 'ProjectsEditGeneral',
  data: () => ({}),
  components: {
    DynamicForm,
    TitleDesc,
    ModalConfirm,
  },
  computed: {
    ...mapGetters({
      users: 'users',
      contacts: 'contacts/all',
      fields: 'projects/fields',
    }),
    project: {
      get() {
        return this.$store.state.projects.single;
      },
      set(value) {
        this.$store.commit('projects/setSingle', value);
      },
    },
  },
  watch: {
    'project.image': async function () {
      await this.save();
    },
  },
  methods: {
    ...mapActions({
      put_project: 'projects/put',
      delete_project: 'projects/delete',
      get_contacts: 'contacts/get_all',
    }),
    async save() {
      await this.put_project(this.project);
    },
    async removeProject() {
      const arhive = await this.$refs.confirmation.ask(
        'Archive this project?',
        'Archived projects can be restored in the archive',
      );
      if (arhive) {
        await this.delete_project(this.project);
        this.$router.push('/projects');
      }
    },
    async setContacts() {
      await this.get_contacts();
      const contactsIndex = this.fields.findIndex((el) => el.key === 'client');
      this.fields[contactsIndex].options = this.contacts;
    },
  },
  async mounted() {
    await this.setContacts();
  },
};
</script>
