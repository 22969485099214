<template>
  <div class="bg-background-light">
    <PageHeading title="Create a task">
      <BaseButton @click="$refs.form.submit()">Save</BaseButton>
    </PageHeading>

    <TitleDesc
      class="base-container"
      title="New task"
      desc="Here you can add a one-off task that doesnt have to belong to a project."
    />

    <div class="base-container base-card">
      <DynamicForm
        ref="form"
        :fields="fields.filter(el => !['notes'].includes(el.key))"
        v-model="task"
        @submit="submit()"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageHeading from '@/components/PageHeading.vue';
import DynamicForm from '@/components/DynamicForm.vue';
import TitleDesc from '@/components/TitleDesc.vue';

export default {
  name: 'home',
  data: () => ({
    task: {
      location: 'atelier',
      blockedBy: [],
      deadline: new Date(),
      state: 'todo',
      images: [],
    },
  }),
  components: {
    PageHeading,
    DynamicForm,
    TitleDesc,
  },
  computed: mapGetters({
    users: 'users',
    fields: 'tasks/fields',
    projects: 'projects/all',
    tasks: 'tasks/all',
  }),
  methods: {
    ...mapActions({
      me: 'me',
      post_task: 'tasks/post',
      get_projects: 'projects/get_all',
      get_tasks: 'tasks/get_all',
    }),
    async submit() {
      await this.post_task(this.task);
      this.$router.push('/tasks/list');
    },
    async setProjects() {
      await this.get_projects();
      const projectsIndex = this.fields.findIndex((el) => el.key === 'project');
      this.fields[projectsIndex].options = this.projects;
      this.fields[projectsIndex].validators = {};
    },
    async setTasks() {
      await this.get_tasks();
      const blockedIndex = this.fields.findIndex(
        (el) => el.key === 'blockedBy',
      );
      this.fields[blockedIndex].options = this.tasks;
    },
  },
  async mounted() {
    if (!this.user) await this.me();
    // restrict access
    this.checkUserRoles('user');
    await this.setProjects();
    await this.setTasks();
  },
};
</script>
