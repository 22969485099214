<template>
  <div>
    <PageHeading title="Templates">
      <BaseButton
        v-if="templates && templates.length > 0"
        @click="create_template()"
      >New template</BaseButton>
    </PageHeading>

    <section
      v-if="templates && templates.length > 0"
      class="sm:rounded-md"
    >

      <ul
        role="
      list"
        class="divide-y divide-gray-200"
      >
        <li
          class="bg-background"
          v-for="(template, index) in templates"
          :key="index"
        >
          <router-link
            :to="`/templates/${template._id}/general`"
            class="block hover:bg-background-light"
          >
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 flex items-center">
                <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                  <div>
                    <p class="text-sm font-medium text-primary truncate">{{ template.name }}</p>
                    <p class="mt-2 flex items-center text-sm text-gray-500">
                      <span
                        v-if="template.desc"
                        class="truncate"
                      >{{template.desc }}</span>
                      <span
                        v-else
                        class="truncate"
                      >
                        ---------
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <ChevronRightIcon
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </section>

    <!-- No projects yet -->
    <section
      v-else
      class="base-container"
    >
      <EmptyState
        class=""
        text="No templates have been created yet, start by creating a new template."
        buttonText="New template"
        @buttonClick="create_template()"
      />
    </section>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ChevronRightIcon } from '@heroicons/vue/solid';
import PageHeading from '@/components/PageHeading.vue';
import EmptyState from '@/components/EmptyState.vue';

export default {
  name: 'Templates',
  components: {
    PageHeading,
    ChevronRightIcon,
    EmptyState,
  },
  computed: mapGetters({
    users: 'users',
    templates: 'templates/all',
  }),
  methods: {
    ...mapActions({
      me: 'me',
      getAll: 'templates/get_all',
      post_template: 'templates/post',
    }),
    async create_template() {
      const template = await this.post_template();
      this.$router.push(`/templates/${template._id}/general`);
    },
  },
  async mounted() {
    console.log('mount templates');
    if (!this.user) await this.me();
    // restrict access
    this.checkUserRoles('user');
    await this.getAll();
  },
};
</script>
