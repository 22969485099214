<template>
  <div
    id="sidebar"
    class=""
  >

    <TransitionRoot
      as="template"
      :show="menus.mobile_menu"
    >
      <Dialog
        as="div"
        class="fixed inset-0 flex z-40 md:hidden"
        @close="menus_toggle('mobile_menu')"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-background-dark">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  @click="menus_toggle('mobile_menu')"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon
                    class="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </TransitionChild>

            <!-- LOGO -->
            <div class="flex-shrink-0 flex items-center px-4">
              <router-link
                to="/"
                @click="menus_toggle('mobile_menu')"
                class="text-left  mr-4"
              >
                <div class="flex flex-col justify-end">
                  <p class="z-10 text-xl">Daan Gysels</p>
                  <h1 class="z-10 font-bold text-4xl">Maatwerk</h1>
                </div>
              </router-link>
            </div>

            <!-- USER WIDGET -->
            <Menu
              as="div"
              class="px-2 mt-4 relative inline-block text-left"
            >
              <div>
                <MenuButton class="group w-full bg-background rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-background-light focus:outline-none focus:ring-none">
                  <span class="flex w-full justify-between items-center">
                    <span class="flex min-w-0 items-center justify-between space-x-3">
                      <img
                        class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                        :src="user.avatar"
                        alt=""
                      />
                      <span class="flex-1 flex flex-col min-w-0">
                        <span class=" text-sm font-medium truncate">{{user.firstname}} {{user.lastname}}</span>
                      </span>
                    </span>
                    <SelectorIcon
                      class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                  </span>
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems class="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                  <div class="py-1">
                    <MenuItem v-slot="{ active }">
                    <router-link
                      @click="menus_close('mobile_menu')"
                      to="/profile"
                      class="text-gray-700 block px-4 py-2 text-sm"
                      role="menuitem"
                      tabindex="-1"
                      id="options-menu-item-0"
                      :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
                    >View profile</router-link>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                    <router-link
                      @click="menus_close('mobile_menu')"
                      to="/timings"
                      class="text-gray-700 block px-4 py-2 text-sm"
                      role="menuitem"
                      tabindex="-1"
                      :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
                      id="options-menu-item-0"
                    >My timings</router-link>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                    <router-link
                      @click="menus_close('mobile_menu')"
                      to="/settings"
                      class="text-gray-700 block px-4 py-2 text-sm"
                      role="menuitem"
                      tabindex="-1"
                      id="options-menu-item-1"
                      :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
                    >Settings</router-link>
                    </MenuItem>
                  </div>
                  <div class="py-1">
                    <MenuItem v-slot="{ active }">
                    <button
                      @click="deauth()"
                      class="text-gray-700 block px-4 py-2 text-sm"
                      role="menuitem"
                      tabindex="-1"
                      :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
                      id="options-menu-item-2"
                    >Logout</button>
                    </MenuItem>
                  </div>
                </MenuItems>
              </transition>
            </Menu>

            <!-- Task Widget -->
            <div class="mt-2 px-2 relative inline-block text-left">
              <div>
                <button
                  type="button"
                  @click.stop="open_task() "
                  class="group w-full mt-1 bg-background rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-background-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary"
                  id="options-menu-button"
                  aria-expanded="false"
                  aria-haspopup="true"
                >
                  <span class="flex w-full justify-between items-center">
                    <span class="flex min-w-0 items-center justify-between space-x-3">
                      <PauseIcon
                        v-if="activeTask"
                        @click.stop="end_task()"
                        class="pulse-primary w-10 h-10 text-primary  rounded-full flex-shrink-0"
                      />
                      <PlayIcon
                        v-else
                        @click.stop="open_tasks()"
                        class="w-10 h-10 text-gray-400 rounded-full flex-shrink-0"
                      />
                      <span class="flex-1 flex flex-col min-w-0">
                        <span
                          v-if="activeTask"
                          class="text-gray-600 text-sm font-bold"
                        >{{activeTask.dynamicName.substring(0, 38)}}{{activeTask.dynamicName.length > 38 ? '...' : ''}}</span>
                        <span
                          v-else
                          class="text-gray-600 text-sm font-medium truncate"
                        >No active task</span>
                      </span>
                    </span>
                  </span>
                </button>
              </div>
            </div>

            <!-- NAVIGATION -->
            <div class="mt-3 flex-1 h-0 overflow-y-auto">
              <nav class="px-2 space-y-1">
                <router-link
                  @click="menus_toggle('mobile_menu')"
                  v-for="item in navigation"
                  :key="item.name"
                  :to="item.href"
                  :class="[item.current ? 'bg-background text-gray-900' : 'text-gray-700 hover:bg-background ', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']"
                >
                  <component
                    :is="item.icon"
                    :class="[item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-4 flex-shrink-0 h-6 w-6']"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </router-link>
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div
          class="flex-shrink-0 w-14"
          aria-hidden="true"
        >
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden lg:flex lg:flex-col lg:w-64 lg:fixed lg:inset-y-0 lg:border-r lg:border-gray-200 lg:pt-5 lg:pb-4 lg:bg-background-dark">
      <!-- Logo -->
      <div class="flex items-center flex-shrink-0 px-6">
        <router-link
          to="/"
          class="text-left mr-4"
        >
          <div class="flex flex-col justify-end">
            <p class="z-10 text-xl">Daan Gysels</p>
            <h1 class="z-10 font-bold text-4xl">Maatwerk</h1>
          </div>
        </router-link>
      </div>
      <!-- SIDEBAR -->
      <div class="mt-6 h-0 flex-1 flex flex-col overflow-y-auto">

        <!-- USER WIDGET -->
        <Menu
          as="div"
          class="px-2 mt-4 relative inline-block text-left"
        >
          <div>
            <MenuButton class="group w-full bg-background rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-background-light focus:outline-none focus:ring-none">
              <span class="flex w-full justify-between items-center">
                <span class="flex min-w-0 items-center justify-between space-x-3">
                  <img
                    class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                    :src="user.avatar"
                    alt=""
                  />
                  <span class="flex-1 flex flex-col min-w-0">
                    <span class=" text-sm font-medium truncate">{{user.firstname}} {{user.lastname}}</span>
                  </span>
                </span>
                <SelectorIcon
                  class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </span>
            </MenuButton>
          </div>
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems class="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                <router-link
                  to="/profile"
                  class="text-gray-700 block px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                  id="options-menu-item-0"
                  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
                >View profile</router-link>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                <router-link
                  to="/timings"
                  class="text-gray-700 block px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
                  id="options-menu-item-0"
                >My timings</router-link>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                <router-link
                  to="/settings"
                  class="text-gray-700 block px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                  id="options-menu-item-1"
                  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
                >Settings</router-link>
                </MenuItem>
              </div>
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                <button
                  @click="deauth()"
                  class="text-gray-700 block px-4 py-2 text-sm"
                  role="menuitem"
                  tabindex="-1"
                  :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
                  id="options-menu-item-2"
                >Logout</button>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>

        <!-- TASK WIDGET -->
        <div class="mt-2 px-2 relative inline-block text-left">
          <div>
            <button
              type="button"
              @click.stop="open_task() "
              class="group w-full mt-1 bg-background rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-background-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary"
              id="options-menu-button"
              aria-expanded="false"
              aria-haspopup="true"
            >
              <span class="flex w-full justify-between items-center">
                <span class="flex min-w-0 items-center justify-between space-x-3">
                  <PauseIcon
                    v-if="activeTask"
                    @click.stop="end_task()"
                    class="pulse-primary w-10 h-10 text-primary  rounded-full flex-shrink-0"
                  />
                  <PlayIcon
                    v-else
                    @click.stop="open_tasks()"
                    class="w-10 h-10 text-gray-400 rounded-full flex-shrink-0"
                  />
                  <span class="flex-1 flex flex-col min-w-0">
                    <span
                      v-if="activeTask"
                      class="text-gray-600 text-sm font-bold"
                    >{{activeTask.dynamicName.substring(0, 38)}}{{activeTask.dynamicName.length > 38 ? '...' : ''}}</span>
                    <span
                      v-else
                      @click.stop="open_tasks()"
                      class="text-gray-600 text-sm font-medium truncate"
                    >No active task</span>
                  </span>
                </span>
              </span>
            </button>
          </div>
        </div>

        <!-- NAVIGATION -->
        <div class="mt-3 flex-1 h-0 overflow-y-auto">
          <nav class="px-2 space-y-1">
            <router-link
              v-for="item in navigation"
              :key="item.name"
              :to="item.href"
              :class="[item.current ? 'bg-background text-gray-900' : 'text-gray-700 hover:bg-background ', 'group flex items-center px-2 py-2 text-base font-medium rounded-md']"
            >
              <component
                :is="item.icon"
                :class="[item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500', 'mr-4 flex-shrink-0 h-6 w-6']"
                aria-hidden="true"
              />
              {{ item.name }}
            </router-link>
          </nav>
        </div>
      </div>
    </div>

    <!-- TASK POPUP -->
    <TaskModal ref="task" />

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import {
  TransitionRoot,
  Dialog,
  TransitionChild,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
} from '@headlessui/vue';
import {
  XIcon,
  HomeIcon,
  UserGroupIcon,
  BriefcaseIcon,
  TemplateIcon,
  DocumentTextIcon,
  PlayIcon,
  PauseIcon,
  ClipboardListIcon,
} from '@heroicons/vue/outline';
import { SelectorIcon } from '@heroicons/vue/solid';
import TaskModal from '@/components/TaskModal.vue';

export default {
  name: 'Sidebar',
  components: {
    UserGroupIcon,
    HomeIcon,
    ClipboardListIcon,
    SelectorIcon,
    BriefcaseIcon,
    TemplateIcon,
    DocumentTextIcon,
    XIcon,
    PlayIcon,
    PauseIcon,
    TransitionRoot,
    Dialog,
    TransitionChild,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    TaskModal,
  },
  data: () => ({}),
  computed: {
    ...mapGetters({
      menus: 'menus',
      user: 'user',
      activeTask: 'tasks/active',
    }),
    navigation() {
      return [
        {
          name: 'Dashboard',
          href: '/',
          icon: HomeIcon,
          current: /^\/$/.test(this.$route.path),
        },
        {
          name: 'Tasks',
          href: '/tasks',
          icon: ClipboardListIcon,
          current: /^\/tasks/.test(this.$route.path),
        },
        {
          name: 'Projects',
          href: '/projects',
          icon: BriefcaseIcon,
          current: /projects/.test(this.$route.path),
        },
        {
          name: 'Templates',
          href: '/templates',
          icon: TemplateIcon,
          current: /templates/.test(this.$route.path),
        },
        {
          name: 'Contacts',
          href: '/contacts',
          icon: UserGroupIcon,
          current: /contacts/.test(this.$route.path),
        },
        {
          name: 'Offertes',
          href: '/offertes',
          icon: DocumentTextIcon,
          current: /offertes/.test(this.$route.path),
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      logout: 'logout',
      me: 'me',
      menus_toggle: 'menus_toggle',
      menus_close: 'menus_close_all',
      end_task: 'tasks/end',
      get_active: 'tasks/get_active',
    }),
    open_tasks() {
      this.menus_close();
      this.$router.push('/tasks/list');
    },
    open_task() {
      if (this.activeTask) {
        this.$refs.task.open(this.activeTask);
        // this.$router.push(`/tasks/${this.activeTask._id}/general`);
        this.menus_close();
      }
    },
    async deauth() {
      await this.logout();
      document.location.reload();
    },
  },
};
</script>
