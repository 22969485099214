<template>
  <BaseModal
    @close="close()"
    :open="is_open"
    width="w-full md:max-w-2xl bg-background-light"
  >
    <div
      @click="commentFocussed = false"
      class="grid z-50 grid-cols-1 md:grid-cols-4 gap-4"
    >
      <div class="md:col-span-3 flex flex-col space-y-4">
        <!-- TITLE DESC -->
        <div class="space-y-3 text-gray-700">
          <span class="flex gap-2 items-start">
            <ClipboardListIcon class="w-8 h-8 " />
            <h1
              class="normal-case text-3xl font-bold w-full"
              @blur="updateTitleDesc()"
              ref="title"
              :contenteditable="!task.isTemplate && !task.taskTemplate"
            >{{task.dynamicName}}</h1>
          </span>
          <span class="flex gap-2 items-start">
            <MenuAlt2Icon class="w-8 h-8 " />
            <h2
              class="text-xl w-full"
              @blur="updateTitleDesc()"
              ref="desc"
              :contenteditable="!task.isTemplate && !task.taskTemplate"
            >{{task.dynamicDesc}}</h2>
          </span>
        </div>
        <!-- IMAGES -->
        <div
          v-if="images && images.length > 0"
          class="flex flex-col"
        >
          <span class="flex gap-2 items-start text-gray-700">
            <PhotographIcon class="w-8 h-8 " />
            <h1 class="capitalize text-lg">Images</h1>
          </span>

          <div class="ml-10 grid grid-cols-6 sm:grid-cols-5 md:grid-cols-4 xl:grid-cols-3 gap-4 mt-4">
            <a
              id="tile"
              class="cursor-pointer relative bg-white border border-background-dark"
              v-for="image in images"
              :key="image.slug"
              :href="`${apiUrl}/${image.slug}`"
              target="_blank"
            >
              <img
                class="h-full w-full object-cover object-center shadow-lg rounded-md"
                :src="`${apiUrl}/${image.slug}`"
              />
            </a>
          </div>
        </div>
        <!-- FILES -->
        <div
          v-if="files && files.length > 0"
          class="flex flex-col"
        >
          <span class="flex gap-2 items-start text-gray-700">
            <PaperClipIcon class="w-8 h-8 " />
            <h1 class="capitalize text-lg">Files</h1>
          </span>
          <div class="ml-10 gap-4 mt-4 flex flex-col">
            <a
              class="cursor-pointer relative flex items-center gap-2"
              v-for="file in files"
              :key="file.slug"
              :href="`${apiUrl}/${file.slug}`"
              target="_blank"
            >
              <div
                id="tile"
                class="w-10 flex items-center justify-center bg-white border border-background-dark rounded-md"
              >
                <PaperClipIcon class="w-4 h-4 mx-auto" />
              </div>
              <p>{{file.name}}</p>
            </a>
          </div>

        </div>
        <!-- INPUT BOX -->
        <div
          @click.stop
          class="flex items-start gap-2 pt-2"
        >
          <img
            class="rounded-full w-8 h-8 border-background-dark border"
            :src="user.avatar"
            :alt="user.name"
          >
          <div
            class="flex-1 flex flex-col gap-2 ring-offset-2 ring-1 rounded bg-white"
            :class="commentFocussed ? 'shadow-md border-1 border-gray-200  ring-primary' : 'ring-gray-200'"
          >
            <textarea
              name="comment"
              id="comment"
              :rows="commentFocussed ? commentModel.split('\n').length : 1"
              cols="1"
              style="resize: none"
              v-model="commentModel"
              placeholder="Write a comment..."
              ref="comment"
              @focus="commentFocussed = true"
              class="p-1 mb-0 border-none outline-none focus:outline-none focus:ring-0"
            />
            <div
              v-if="commentFocussed"
              class="flex justify-between items-end p-1"
            >
              <BaseButton
                @click="addComment()"
                :class="commentModel.length <= 0 ? 'opacity-50' : ''"
              >Save</BaseButton>
              <div class="pr-1 flex">
                <PhotographIcon
                  @click.stop="$refs.images.click()"
                  class="w-8 h-8 text-gray-600 cursor-pointer hover:text-black"
                />
                <PaperClipIcon
                  @click.stop="$refs.files.click()"
                  class="w-8 h-8 text-gray-600 cursor-pointer hover:text-black"
                />

              </div>
            </div>
          </div>
        </div>
        <!-- COMMENTS -->
        <div
          v-if="task.comments && task.comments.length > 0"
          class="flex flex-col pt-4"
        >
          <span class="flex gap-2 items-start text-gray-700">
            <AnnotationIcon class="w-8 h-8 " />
            <h1 class="capitalize text-lg">Comments</h1>
          </span>
          <span
            v-for="comment in comments "
            :key="comment._id"
            class="flex gap-2 mt-4 items-start text-gray-700"
          >
            <img
              class="mt-1 border-background-dark border w-8 h-8 rounded-full"
              :src="`${apiUrl}/avatars/${comment.author.avatar}`"
              :alt="comment.author.name"
            >
            <div class="flex flex-col w-full">
              <span class="space-x-2">
                <span class="font-bolder text-black">{{comment.author.firstname}}</span>
                <span class="text-sm">{{formatDate(comment.createdAt)}}</span>
              </span>
              <p class="border border-b-2 border-background-dark bg-white rounded-md mt-1 py-2 px-3  w-full">{{comment.content}}</p>
            </div>
          </span>
        </div>
      </div>
      <div class="md:col-span-3 md:hidden border-t border-background-dark"></div>
      <!-- OPTIONS -->
      <div class="md:col-span-1 flex flex-col justify-between">
        <div class="grid sm:grid-cols-2 md:grid-cols-1 gap-2 md:gap-0">
          <BaseSelect
            v-model="task.project"
            :options="projects"
            optionKey="_id"
            optionLabel="name"
            label="project"
            name="project"
          />

          <BaseSelect
            v-model="task.location"
            :options="locations"
            optionKey="key"
            optionLabel="label"
            label="location"
            name="location"
          />
          <BaseSelect
            v-model="task.state"
            :options="states"
            optionKey="key"
            optionLabel="label"
            label="state"
            name="state"
            :disabled="task.state == 'blocked'"
          />
          <BaseSelect
            v-model="task.blockedBy"
            :options="tasks"
            :single="false"
            optionKey="_id"
            optionLabel="dynamicName"
            name="blockedBy"
            label="blocked by"
          />
          <BaseDate
            v-model="task.deadline"
            label="deadline"
            name="deadline"
          />
        </div>
        <div class="my-8 border-t border-background-dark"></div>
        <div class="grid sm:grid-cols-2 md:grid-cols-1 gap-2">
          <BaseButton @click="close()">Save</BaseButton>
          <BaseButton :disabled="true">Copy</BaseButton>
          <BaseButton :disabled="true">Add to template</BaseButton>
          <BaseButton
            @click="archive()"
            type="danger"
          >Archive</BaseButton>
        </div>
      </div>

      <!-- CONFIRMATION MODAL -->
      <ModalConfirm ref="confirmation" />

      <!-- FILE UPLOAD INPUT -->
      <input
        class="hidden"
        ref="files"
        type="file"
        name="image"
        id="image"
        @change="uploadFile"
      >

      <!-- IMAGE UPLOAD INPUT -->
      <input
        class="hidden"
        ref="images"
        type="file"
        name="image"
        id="image"
        @change="uploadImage"
      >

    </div>
  </BaseModal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  MenuAlt2Icon,
  ClipboardListIcon,
  PhotographIcon,
  AnnotationIcon,
  PaperClipIcon,
} from '@heroicons/vue/outline';
import ModalConfirm from '@/components/ModalConfirm.vue';

export default {
  components: {
    MenuAlt2Icon,
    ClipboardListIcon,
    PhotographIcon,
    AnnotationIcon,
    ModalConfirm,
    PaperClipIcon,
  },
  data: () => ({
    debounceTimeout: false,
    debounceInterval: 1000,
    commentModel: '',
    commentFocussed: false,
    apiUrl: process.env.VUE_APP_API_URL,
    is_open: false,
    taskId: '',
  }),
  computed: {
    ...mapGetters({
      user: 'user',
      projects: 'projects/all',
      locations: 'tasks/locations',
      states: 'tasks/states',
    }),
    tasks: {
      get() {
        return this.$store.state.tasks.all.filter(
          (task) => task._id !== this.task._id && task.state !== 'done',
        );
      },
    },
    task: {
      get() {
        const task = this.$store.state.tasks.single;
        if (!task.files) task.files = [];
        return task;
      },
      set(value) {
        this.$store.commit('tasks/setSingle', value);
      },
    },
    comments() {
      const { comments } = this.task;
      return comments.sort(
        (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      );
    },
    images() {
      if (!this.task.files) return [];
      return this.task.files.filter((file) => /image/.test(file.mime));
    },
    files() {
      if (!this.task.files) return [];
      return this.task.files.filter((file) => !/image/.test(file.mime));
    },
  },
  methods: {
    ...mapActions({
      get_tasks: 'tasks/get_all',
      get_projects: 'projects/get_all',
      get_task: 'tasks/get_single',
      put_task: 'tasks/put',
      delete_task: 'tasks/delete',
      post_comment: 'tasks/post_comment',
      post_image: 'tasks/post_image',
      post_file: 'tasks/post_file',
    }),
    async open(task) {
      this.taskId = task._id;
      await this.get_task(this.taskId);
      this.is_open = true;
    },
    async close() {
      await this.save(false);
      await this.get_tasks();
      this.is_open = false;
    },
    async save(debounce = true) {
      if (this.debounceTimeout) window.clearTimeout(this.debounceTimeout);
      if (debounce) {
        this.debounceTimeout = window.setTimeout(async () => {
          await this.put_task(this.task);
          await this.get_task(this.taskId);
        }, this.debounceInterval);
      } else {
        await this.put_task(this.task);
        await this.get_task(this.taskId);
      }
    },
    async archive() {
      const answer = await this.$refs.confirmation.ask(
        'Archive task?',
        'The task will still be available in the archive.',
      );
      if (!answer) return;
      await this.delete_task(this.task._id);
      this.is_open = false;
    },
    async uploadImage() {
      await this.post_image(this.$refs.images.files[0]);
    },
    async uploadFile() {
      await this.post_file(this.$refs.files.files[0]);
    },
    async addComment() {
      if (this.commentModel.length <= 0) return;
      console.log('add comment');
      await this.post_comment(this.commentModel);
      this.commentModel = '';
      this.commentFocussed = false;
    },
    async updateTitleDesc() {
      const title = this.$refs.title.innerText;
      const desc = this.$refs.desc.innerText;
      this.task.name = title;
      this.task.desc = desc;
      await this.save();
    },
    formatDate(date) {
      const d = new Date(date);
      return `${d.getDate()}/${
        d.getMonth() + 1
      }/${d.getFullYear()} @ ${d.getHours()}:${d.getMinutes()}`;
    },
  },
  async mounted() {
    await this.get_tasks();
    await this.get_projects();
    // await this.get_task(this.taskId);
  },
};
</script>

<style scoped>
#modal-body {
  border-radius: 3px !important;
  padding: 16px !important;
}
#tile {
  aspect-ratio: 1;
}
/* #image-tile:hover #image-controls {
  @apply visible;
} */
</style>
