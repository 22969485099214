<template>
  <div class="z-50 fixed bottom-4 right-4 flex flex-col  w-3/4 sm:w-1/2 xl:w-1/4">
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div
      v-for="(notification, index) of notifications"
      :key="index"
      aria-live="assertive"
      class="z-50 inset-0 flex items-end pointer-events-none sm:items-start"
    >
      <div class="w-full flex flex-col items-center sm:items-end">
        <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
        <transition
          enter-active-class="transform ease-out duration-300 transition"
          enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-if="notification.show"
            class="max-w-sm w-full bg-white mt-4 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
          >
            <div class="p-4">
              <div class="flex items-start">
                <div class="flex-shrink-0">
                  <XCircleIcon
                    class="h-6 w-6 text-danger"
                    aria-hidden="true"
                    v-if="notification.type == 'error'"
                  />
                  <CheckCircleIcon
                    class="h-6 w-6 text-secondary"
                    aria-hidden="true"
                    v-if="notification.type == 'info'"
                  />
                  <InformationCircleIcon
                    class="h-6 w-6 text-primary"
                    aria-hidden="true"
                    v-if="notification.type == 'success'"
                  />
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                  <p
                    class="text-sm font-medium"
                    :class="{
                      'text-danger': notification.type == 'error',
                      'text-secondary': notification.type == 'info',
                      'text-primary': notification.type == 'success',
                    }"
                  >
                    {{ notifications[0].title }}
                  </p>
                  <p class="mt-1 text-sm text-gray-500">
                    {{ notifications[0].body }}
                  </p>
                </div>
                <div class="ml-4 flex-shrink-0 flex">
                  <button
                    @click="close(index)"
                    class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    <span class="sr-only">Close</span>
                    <XIcon
                      class="h-5 w-5"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  XCircleIcon,
  InformationCircleIcon,
  CheckCircleIcon,
} from '@heroicons/vue/outline';
import { XIcon } from '@heroicons/vue/solid';

export default {
  components: {
    XCircleIcon,
    CheckCircleIcon,
    InformationCircleIcon,
    XIcon,
  },
  computed: {
    ...mapGetters(['notifications']),
  },
  methods: mapActions({ close: 'closeNotification' }),
};
</script>
