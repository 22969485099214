<template>
  <div class="bg-background-light ">

    <!-- General settings -->
    <TitleDesc
      class="base-container mb-2"
      title="Basic settings"
      desc="Here you can edit the basic settings of your new template."
    >
      <BaseButton
        type="danger"
        @click="archive()"
      >archive</BaseButton>
      <BaseButton
        type="primary"
        class="row-start-1 sm:col-start-2"
        @click="save()"
      >Save</BaseButton>
    </TitleDesc>

    <section class="base-card base-container">
      <DynamicForm
        :fields="fields"
        v-model="template"
        @submit="save()"
      >
        <BaseButton
          type="danger"
          @click.prevent="archive()"
        >archive</BaseButton>
      </DynamicForm>
    </section>

    <ModalConfirm ref="confirmation" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DynamicForm from '@/components/DynamicForm.vue';
import TitleDesc from '@/components/TitleDesc.vue';
import ModalConfirm from '@/components/ModalConfirm.vue';

export default {
  name: 'ProjectsEditGeneral',
  data: () => ({
    popupConfirmDelete: false,
  }),
  components: {
    DynamicForm,
    TitleDesc,
    ModalConfirm,
  },
  computed: {
    ...mapGetters({
      users: 'users',
      fields: 'templates/fields',
    }),
    template: {
      get() {
        return this.$store.state.templates.single;
      },
      set(value) {
        return this.$store.commit('templates/setSingle', value);
      },
    },
  },
  methods: {
    ...mapActions({
      put_template: 'templates/put',
      delete_template: 'templates/delete',
    }),
    async save() {
      await this.put_template(this.template);
    },
    async archive() {
      const answer = await this.$refs.confirmation.ask(
        'Archive this template?',
        'Archived templates can still be restored in the archive.',
      );
      if (!answer) return;
      await this.delete_template(this.template);
      this.$router.push('/templates');
    },
  },
};
</script>
