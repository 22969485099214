<template>
  <div
    id="app"
    @click="menus_close_all()"
    class="h-full"
  >
    <div class="h-full">
      <!-- Sidebar -->
      <Sidebar />
      <!-- Main column -->
      <div class="lg:pl-64 h-full">
        <!-- Header -->
        <Header v-if="false" />
        <!-- Main content -->
        <main class="h-full">
          <router-view
            class="h-full"
            :class="{'pt-20': !/auth/.test($route.path)}"
          />
        </main>
      </div>
    </div>
    <Notifications />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Sidebar from '@/components/Sidebar.vue';
import Header from '@/components/Header.vue';
import Notifications from '@/components/Notifications.vue';

export default {
  components: {
    Sidebar,
    Header,
    Notifications,
  },
  computed: mapGetters(['user']),
  methods: mapActions({
    me: 'me',
    menus_close_all: 'menus_close_all',
    push_notification: 'pushNotification',
  }),
  // error handler
  // errorCaptured(err) {
  //   // return false to stop error propagation
  //   // return false;
  // },
  async mounted() {
    // if (!this.user) await this.me();
    // clearTimeout();
    // function sendNotification(_self) {
    //   _self.push_notification({
    //     type: 'success',
    //     title: 'Hello world!',
    //     body: 'lalalallal',
    //   });
    //   setTimeout(() => {
    //     sendNotification(_self);
    //   }, 800);
    // }
    // sendNotification(this);
  },
};
</script>
