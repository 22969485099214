module.exports = {
  data: () => ({
    dirtyFields: {},
  }),
  computed: {
    valid() {
      return Object.keys(this.errors).length < 1;
    },
    errors() {
      if (!this.fields) return console.errors('fields is not set!');
      const checkFields = (fields, model) => {
        const errors = {};
        for (let i = 0; i < fields.length; i += 1) {
          const field = fields[i];
          if (field.fields) {
            const nested = checkFields(field.fields, model[field.key]);
            Object.keys(nested)
              .forEach(subkey => errors[`${field.key}.${subkey}`] = nested[subkey]);
          }
          if (field.validators) {
            Object.keys(field.validators || {})
              .reverse()
              .forEach(validator => {
                const error = field.validators[validator](model[field.key] || '');
                if (error) errors[field.key] = error;
              });
          }
        }
        return errors;
      };
      const errors = checkFields(this.fields, this.modelValue);
      return errors;
    },
  },
  methods: {
    dirty(key) {
      this.dirtyFields[key] = true;
    },
    dirtyAll(fields = false) {
      const dirtyNested = (fields) => {
        const dirtyFields = {};
        for (let i = 0; i < fields.length; i += 1) {
          const field = fields[i];
          if (field.fields) {
            const nested = dirtyNested(field.fields);
            Object.keys(nested).forEach(subkey => dirtyFields[`${field.key}.${subkey}`] = nested[subkey]);
          } else dirtyFields[field.key] = true;
        }
        return dirtyFields;
      };
      this.dirtyFields = dirtyNested(fields || this.fields);
      this.$forceUpdate();
    },
    clean(key) {
      this.dirtyFields[key] = false;
    },
    cleanAll() {
      this.dirtyFields = {};
    },

    getError(key) {
      if (!this.dirtyFields[key]) return '';
      if (!this.errors[key]) return '';
      return this.errors[key];
    },
  },
};
