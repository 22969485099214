<template>
  <div class="">
    <PageHeading title="Offertes">
      <BaseButton @click="createOfferte()">New offerte</BaseButton>
    </PageHeading>

    <div class="sm:rounded-md">
      <ul
        role="
      list"
        class="divide-y divide-gray-200"
      >
        <li
          class="bg-background"
          v-for="(offerte, index) in offertes"
          :key="index"
        >
          <router-link
            :to="`/offertes/${offerte._id}`"
            class="block hover:bg-background-light"
          >
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 flex items-center">
                <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                  <div>
                    <p class="text-sm font-medium text-primary truncate">
                      <b>{{new Date(offerte.createdAt).getFullYear()}}-{{('0000'+offerte.factuurnr).split('').slice(-4).join('')}}</b>
                    </p>
                    <p class="mt-2 flex items-center text-sm text-gray-500">
                      <UserIcon
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span v-if="offerte.contact && offerte.contact.name && offerte.contact.name.replace(/ /g, '').length > 0">
                        {{offerte.contact.name}}
                      </span>
                      <span v-else-if="offerte.contact && offerte.contact.address">
                        {{formatAddress(offerte.contact.address)}}
                      </span>
                      <span
                        class="text-gray-400"
                        v-else
                      >No contact set</span>
                    </p>
                  </div>

                  <div class="hidden md:block">
                    <div>
                      <p class="mt-2 flex items-center text-sm text-gray-500">
                        <CalendarIcon
                          class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span
                          v-if="offerte.period && offerte.period.start && offerte.period.end"
                          class="truncate"
                        >{{ formatDate(offerte.period.start) }} - {{ formatDate(offerte.period.end) }}</span>
                        <span
                          v-else
                          class="text-gray-400"
                        >No period set</span>
                      </p>

                      <p class="mt-2 flex items-center text-sm text-gray-500">
                        <CurrencyEuroIcon
                          class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span v-if="offerte.total">{{offerte.total.toFixed(2)}}</span>
                        <span
                          v-else
                          class="text-gray-400"
                        >No value</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <ChevronRightIcon
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  ChevronRightIcon,
  CalendarIcon,
  CurrencyEuroIcon,
  UserIcon,
} from '@heroicons/vue/solid';
import PageHeading from '@/components/PageHeading.vue';

export default {
  name: 'home',
  components: {
    PageHeading,
    CalendarIcon,
    CurrencyEuroIcon,
    ChevronRightIcon,
    UserIcon,
  },
  computed: mapGetters({
    users: 'users',
    offertes: 'offertes/all',
  }),
  methods: {
    ...mapActions({
      me: 'me',
      getAll: 'offertes/get_all',
      post_offertes: 'offertes/post',
    }),
    // nicely format an address
    formatAddress(address) {
      if (!address || !address.street) return '----';
      let str = `${address.street}`;
      str += `${address.house_number ? ' ' : ''}${address.house_number || ''}`;
      str += `${address.zip || address.city ? ', ' : ''}`;
      str += `${address.zip || ''} ${address.city || ''}`;
      return str;
    },

    formatDate(d) {
      const date = new Date(d);
      if (date === 'Invalid Date') return 'set date';
      return `${`0${(date.getMonth() + 1).toString()}`.slice(-2)}-${`0${date
        .getDate()
        .toString()}`.slice(-2)}-${date.getFullYear()}`;
    },
    async createOfferte() {
      const offerte = await this.post_offertes();
      this.$router.push(`/offertes/${offerte._id}`);
    },
  },
  async mounted() {
    if (!this.user) await this.me();
    console.log('mount home');
    // restrict access
    this.checkUserRoles('user');
    await this.getAll();
  },
};
</script>
