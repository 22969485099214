<template>
  <div class="bg-background-light ">
    <PageHeading :title="`Edit template: ${template.name}`">
    </PageHeading>

    <!-- Tabs -->
    <div
      id="tabs"
      class="fixed top-16 z-10 bg-background-dark"
    >
      <div class="sm:block overflow-x-auto">
        <div class="border-b border-gray-200">
          <nav
            class="-mb-px flex w-full"
            aria-label="Tabs"
          >
            <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
            <router-link
              :to="`/templates/${$route.params.templateId}/general`"
              class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-full py-4 px-1 text-center border-b-2 font-medium text-sm"
              :class="{ 'border-primary text-primary': /general/.test($route.path)}"
            >
              General
            </router-link>
            <router-link
              :to="`/templates/${$route.params.templateId}/fields`"
              class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-full py-4 px-1 text-center border-b-2 font-medium text-sm"
              :class="{ 'border-primary text-primary': /fields/.test($route.path)}"
            >
              Fields
            </router-link>
            <router-link
              :to="`/templates/${$route.params.templateId}/tasks`"
              class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-full py-4 px-1 text-center border-b-2 font-medium text-sm"
              :class="{ 'border-primary text-primary': /tasks/.test($route.path)}"
            >
              Tasks
            </router-link>
          </nav>
        </div>
      </div>
    </div>

    <router-view class="relative top-10"></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageHeading from '@/components/PageHeading.vue';

export default {
  name: 'templatesEdit',
  components: {
    PageHeading,
  },
  computed: mapGetters({
    template: 'templates/single',
  }),
  methods: {
    ...mapActions({
      me: 'me',
      get_template: 'templates/get_single',
    }),
    navigateTo(event) {
      this.$router.push(event.target.value);
    },
  },
  async mounted() {
    if (!this.user) await this.me();
    // restrict access
    this.checkUserRoles('user');
    // fetch the current template in the state
    await this.get_template(this.$route.params.templateId);
  },
};
</script>
