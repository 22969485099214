<template>
  <BaseModal
    :open="open"
    @close="open = false"
    :title="title"
  >
    <p class="text-gray-600 mb-2">{{body}}</p>
    <div class="flex w-full justify-end space-x-2 pt-2">
      <BaseButton
        class="flex-1"
        @click.prevent="answer(false)"
        type="danger"
      >
        no
      </BaseButton>
      <BaseButton
        class="flex-1"
        @click.prevent="answer(true)"
      >
        yes
      </BaseButton>
    </div>
  </BaseModal>

</template>

<script>
export default {
  data: () => ({
    open: false,
    title: '',
    body: '',
    resolve: null,
    reject: null,
  }),
  methods: {
    async ask(title, body) {
      this.open = true;
      this.title = title;
      this.body = body;
      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },
    answer(response) {
      this.open = false;
      this.resolve(response);
    },
  },
};
</script>
