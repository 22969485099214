<template>
  <div>
    <label
      v-if="label"
      :for="name"
      class="block text-sm font-medium text-gray-700"
    >{{ label }}</label>

    <div class="mt-1 relative rounded-md">

      <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
      <button
        type="button"
        class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 shadow-lg border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        :class="{
          'bg-primary': modelValue,
          'bg-background': !modelValue
        }"
        role="switch"
        :aria-checked="modelValue"
        @click.stop="$emit('update:modelValue', !modelValue)"
      >
        <span class="sr-only">Use setting</span>
        <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
        <span
          aria-hidden="true"
          class="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          :class="{
            'translate-x-5': modelValue
          }"
        ></span>
      </button>

      <div
        v-if="error"
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      >
        <ExclamationCircleIcon class="h-5 w-5 text-red-500" />
      </div>
    </div>
    <p
      v-if="!error && hint"
      class="mt-2 text-sm text-gray-500"
      id="email-description"
    >
      {{ hint }}
    </p>
    <p
      v-if="error"
      class="mt-2 text-sm text-red-600"
      :id="`${name}-error`"
    >
      {{ error }}
    </p>

  </div>
</template>

<script>
import { ExclamationCircleIcon } from '@heroicons/vue/solid';

export default {
  components: { ExclamationCircleIcon },
  props: {
    modelValue: { type: Boolean, default: false },
    name: { type: String, required: true },
    label: { type: String, default: '' },
    error: { type: String, default: '' },
    hint: { type: String, default: '' },
  },
};
</script>
