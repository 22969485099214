<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  v-if="selectable"
                  scope="col"
                  class="relative px-6 py-3 max-w-min"
                >
                  <BaseCheckbox
                    @update:modelValue="toggleAll()"
                    v-model="selectAll"
                    name=""
                    class="max-w-min"
                  />
                </th>

                <th
                  v-for="field in fields"
                  :key="field.key"
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ field.label }}
                </th>

                <th
                  v-if="buttons"
                  scope="col"
                  class="relative px-6 py-3"
                >
                  <span class="sr-only">Edit</span>
                  <span class="sr-only">Delete</span>
                </th>

                <th
                  v-if="config"
                  class="cursor-pointer max-w-max min-w-max"
                >
                  <button
                    @click="$emit('config')"
                    class="flex px-2 justify-center items-center h-full w-full"
                  >
                    <CogIcon class="w-5 h-5 hover:opacity-60" />
                  </button>
                </th>

              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr
                ref="table"
                @click="$emit('clickElement', object)"
                v-for="object in data"
                :key="object._id"
                class="cursor-pointer hover:bg-gray-50"
              >
                <td
                  v-if="selectable"
                  class=" w-8 px-6 py-4  whitespace-nowrap text-right text-sm font-medium space-x-4"
                >
                  <BaseCheckbox
                    v-model="selected[object._id]"
                    class="max-w-min"
                    name=""
                  />
                </td>

                <td
                  v-for="field in fields"
                  :key="field.key"
                  class="px-6 whitespace-nowrap text-sm font-medium text-gray-900"
                >
                  <div v-if="field.type === 'text' || field.type == 'textarea'">
                    <p class="w-48 py-4 truncate">{{ object[field.key] }}</p>
                  </div>

                  <div v-else-if="field.type === 'bool'">
                    <div class="flex items-center h-5">
                      <input
                        v-model="object[field.key]"
                        :name="field.label"
                        type="checkbox"
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        disabled
                      />
                    </div>
                  </div>

                  <div v-else-if="field.type === 'date'">
                    <p class="w-48 py-4">{{ formatDate(object[field.key]) }}</p>
                  </div>

                  <div v-else-if="field.type === 'select' && object[field.key] && object[field.key][field.optionLabel]">
                    <p class="w-48 py-4">{{ object[field.key][field.optionLabel] }}</p>
                  </div>

                  <div v-else>
                    <p class="w-48 py-4">{{ object[field.key] }}</p>
                  </div>
                </td>

                <td
                  v-if="buttons"
                  class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium space-x-4"
                >
                  <button
                    @click.stop="$emit('editElement', object)"
                    class="text-indigo-600 hover:text-indigo-900"
                  >
                    Edit
                  </button>
                  <button
                    @click.stop="$emit('deleteElement', object)"
                    class="text-red-600 hover:text-red-900"
                  >
                    Delete
                  </button>
                </td>
                <td v-if="config"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CogIcon } from '@heroicons/vue/outline';

export default {
  props: {
    config: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    buttons: { type: Boolean, default: true },
    selectable: { type: Boolean, default: false },
  },
  components: {
    CogIcon,
  },
  data: () => ({
    selected: {},
    selectAll: false,
  }),
  watch: {
    selected: {
      deep: true,
      handler() {
        this.$emit('select', this.selected);
      },
    },
    data() {
      Object.keys(this.selected).forEach((key) => {
        if (this.data.findIndex((el) => el._id === key) < 0) delete this.selected[key];
      });
      if (Object.keys(this.selected).length < 1) this.selectAll = false;
    },
  },
  methods: {
    formatDate(date) {
      const d = new Date(date);
      return `${`0${d.getDate()}`.slice(-2)}/${`0${d.getMonth() + 1}`.slice(
        -2,
      )}/${d.getFullYear().toString().substring(2)}`;
    },
    toggleAll() {
      // invert
      // for (let i = 0; i < this.data.length; i += 1) {
      //   this.selected[this.data[i]._id] = !this.selected[this.data[i]._id];
      // }

      // toggle
      for (let i = 0; i < this.data.length; i += 1) {
        this.selected[this.data[i]._id] = !this.selectAll;
      }
      this.$emit('select', this.selected);
    },
  },
};

/* fields: [{
  type: '',
  label: '',
  key: '',
  editable: true,
}] */
</script>
