<template>
  <div
    id="title-desc"
    class="flex flex-col md:flex-row justify-between"
  >
    <div class="">
      <h2 class="text-xl">{{title}}</h2>
      <p
        class="text-gray-600"
        v-html="desc"
      ></p>
    </div>
    <div class="hidden flex-shrink-0 gap-2  grid-cols-1 sm:grid-cols-2 md:space-x-2 md:block mt-4 md:mt-0">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: { type: String, required: true },
    desc: { type: String, required: true },
  },
};
</script>
