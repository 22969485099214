import envalidate from '@siliconminds/envalidate';
import Api from '@siliconminds/api';
import { required } from '@siliconminds/validation/validators';

envalidate('VUE_APP_API_URL');

const api = new Api(process.env.VUE_APP_API_URL);

const endpoint = 'images';

export default {
  namespaced: true,
  state: {
    fields: [
      {
        label: 'File',
        key: 'slug',
        type: 'image',
        editable: true,
        validators: { required },
      },
    ],
    all: [],
    single: {},
  },
  getters: {
    fields: (state) => state.fields,

    all: (state) => state.all,
    single: (state) => state.single,
  },
  actions: {
    async get_all({ commit, dispatch }, payload = {}) {
      const res = await api.get(`${endpoint}/model`, {
        query: payload,
      });
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      return commit('setAll', res.data);
    },
    async get_single({ commit, dispatch }, id) {
      const res = await api.get(`${endpoint}/model/${id}`);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      return commit('setSingle', res.data);
    },
    async post({ commit, dispatch }, payload) {
      const res = await api.post(endpoint, payload);
      if (!res.success) return commit('pushError', res.error, { root: true });
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      return commit('push', res.data);
    },
    async put({ commit, dispatch }, payload) {
      const res = await api.put(`${endpoint}/${payload._id}`, payload);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      return commit('update', payload);
    },
    async delete({ commit, dispatch }, payload) {
      const res = await api.delete(`${endpoint}/${payload._id}`);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      commit('delete', payload);
      return true;
    },
  },
  mutations: {
    setAll: (state, items) => { state.all = items; },
    setSingle: (state, item) => { state.single = item; },
    push: (state, item) => { state.all.push(item); },
    update: (state, item) => { state.all[state.all.findIndex((el) => el._id.toString() === item._id.toString())] = item; },
    delete: (state, item) => { state.all = state.all.filter(el => el._id.toString() !== item._id.toString()); },

    setPagination: (state, pagination) => { state.pagination = pagination; },
    setPage: (state, page) => { state.pagination.page += page; },
  },
};
