<template>
  <div>
    <form
      @submit.prevent.stop="submit()"
      class="space-y-2"
    >
      <div
        v-for="field in fields.filter(f => f.editable)"
        :key="field.key"
      >
        <DynamicField
          :field="field"
          :modelValue="(modelValue || {})[field.key]"
          :error="getError(field.key)"
          @update:modelValue="update(field.key, $event)"
          @blur="dirty(field.key)"
          @focus="clean(field.key)"
        />
      </div>
      <!-- <div class="pt-4 flex justify-end space-x-2"> -->
      <div class="gap-2 sm:-space-y-0 grid grid-cols-1 sm:grid-cols-2 md:flex md:justify-end pt-6 md:mt-0">
        <slot />
        <BaseButton
          class="sm:col-start-2 row-start-1"
          @click.prevent="submit()"
        >Save</BaseButton>
      </div>
    </form>
  </div>
</template>

<script>
import validation from '@/mixins/validation';
import DynamicField from '@/components/DynamicField.vue';

export default {
  mixins: [validation],
  props: {
    fields: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
  },
  components: {
    DynamicField,
  },
  methods: {
    update(key, value) {
      // deep copy the old object
      const newValue = { ...this.modelValue };
      // update the value
      newValue[key] = value;
      // emit the update
      this.$emit('update:modelValue', newValue);
    },
    submit() {
      this.dirtyAll(this.fields);
      if (this.valid) {
        this.$emit('submit');
        this.cleanAll(this.fields);
      } else {
        console.error(this.errors);
      }
    },
  },
};

/* fields: [{
  type: '',
  label: '',
  key: '',
  editable: true,
}] */
</script>
