import envalidate from '@siliconminds/envalidate';
import Api from '@siliconminds/api';
import router from '@/router';

envalidate('VUE_APP_API_URL');

const api = new Api(process.env.VUE_APP_API_URL);

export default {
  state() {
    return {
      user: false,
    };
  },
  getters: {
    user: (state) => state.user,
  },
  actions: {
    async login({ dispatch }, options) {
      const res = await api.post('auth/login', options.data || options);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      return dispatch('me', {
        errors: true,
        ...options,
      });
    },
    async magiclink({ dispatch }, options) {
      const res = await api.post('auth/magiclink', options.data || options);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      dispatch('pushNotification', { type: 'success', title: 'Magiclink on its way!' }, { root: true });
    },
    async use_magiclink({ dispatch }, options) {
      const res = await api.get('auth/magiclink', {
        headers: {
          Authorization: `Bearer ${options.data.token || options.token}`,
        },
      });
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      return dispatch('me', {
        errors: true,
        ...options,
      });
    },
    async me({ commit, dispatch }, options = {}) {
      const res = await api.get('auth/me');
      if (!res.success) {
        // dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
        return false;
      }
      // check if the user is working on a task
      dispatch('tasks/get_active');
      if (options.redirect) router.push(options.redirect);
      return commit('setUser', res.user);
    },
    async logout({ commit }) {
      const res = await api.put('auth/logout');
      if (res.success) commit('setUser', false);
      // TODO push to logged out page!
      router.push('/');
    },
    async register({ commit, dispatch }, options) {
      const passwords_match = (options.data.password || options.password) === (options.data.password2 || options.password2);
      if (!passwords_match) return dispatch('pushNotification', { type: 'error', title: 'passwords dont match!' }, { root: true });
      const res = await api.post('auth/register', options.data || options);
      if (!res.success) return commit('setError', res.error);
      if (options.redirect) router.push(options.redirect);
      return true;
    },
    async forgot_password({ dispatch }, options) {
      const res = await api.post('auth/pass-reset', options.data || options);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      dispatch('pushNotification', { type: 'success', title: 'Password reset link on its way!' }, { root: true });
    },
    async reset_password({ dispatch }, options) {
      const passwords_match = (options.data.password || options.password) === (options.data.password2 || options.password2);
      if (!passwords_match) return dispatch('pushNotification', { type: 'error', title: 'passwords dont match!' }, { root: true });
      const res = await api.put('auth/pass-reset', options.data || options);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      if (options.redirect) router.push(options.redirect);
      return true;
    },
  },
  mutations: {
    setError: (state, error) => { state.error = error; },
    setUser: (state, user) => {
      state.user = { ...user, avatar: `${process.env.VUE_APP_API_URL}/avatars/${user.avatar}` };
    },
  },
};
