<template>
  <div class="p-4 border-4 border-dashed gap-2 border-gray-300 rounded-2xl flex flex-col md:flex-row md:justify-between items-end md:items-start">
    <p class="text-lg text-left w-full text-gray-500">{{text}}</p>
    <BaseButton
      class="flex-shrink-0"
      v-if="buttonText && buttonText.length > 0"
      @click="$emit('buttonClick')"
      type="primary"
    >
      {{buttonText}}
    </BaseButton>
  </div>

</template>

<script>
export default {
  props: {
    text: { type: String, required: true },
    buttonText: { type: String },
  },
};
</script>
