export default {
  // state for the store
  state: {
    mobile_user: false,
    mobile_menu: false,
    user: false,
  },
  // getters for components
  getters: {
    menus: (state) => state,
  },
  // async actions called by components
  actions: {
    menus_toggle({ commit, state }, payload) {
      console.log('toggle');
      if (!state[payload] && !['mobile_user'].includes(payload)) commit('close_all');
      commit('toggle', payload);
    },
    menus_close_all({ commit }) {
      commit('close_all');
    },
  },
  // commit changes to the store
  mutations: {
    toggle: (state, menu) => { state[menu] = !state[menu]; },
    close_all: (state) => Object.keys(state).forEach(key => { state[key] = false; }),
  },
};
