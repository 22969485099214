<template>
  <div>
    <TransitionRoot
      as="template"
      :show="open"
    >
      <div
        class="fixed z-50 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          @click="$emit('close')"
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block"
        >

          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div
              id="background"
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >&#8203;</span>

          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              id="modal-body"
              class="inline-block align-bottom bg-white rounded-lg px-4 pt-2 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6 sm:pt-4"
              :class="`${width}`"
            >
              <div>
                <div class="">
                  <h3
                    class="text-center text-xl leading-6 font-semibold text-gray-900"
                    id="modal-title"
                  >
                    {{ title }}
                  </h3>
                  <div
                    @click.stop
                    class="mt-4"
                  >
                    <slot />
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>

    </TransitionRoot>
  </div>
</template>

<script>
import { TransitionRoot, TransitionChild } from '@headlessui/vue';

export default {
  components: {
    TransitionRoot,
    TransitionChild,
  },
  props: {
    open: Boolean,
    title: String,
    width: { type: String, default: 'max-w-sm sm:w-full' },
  },
};
</script>

<style scoped>
</style>
