<template>
  <div>
    <PageHeading title="Projects">
      <BaseButton
        v-if="projects && projects.length > 0"
        @click="$router.push('/projects/create')"
      >New project</BaseButton>
    </PageHeading>

    <section
      v-if="projects && projects.length > 0"
      class="sm:rounded-md"
    >
      <ul
        role="
      list"
        class="divide-y divide-gray-200"
      >
        <li
          class="bg-background"
          v-for="(project, index) in projects"
          :key="index"
        >
          <router-link
            :to="`/projects/${project._id}/general`"
            class="block hover:bg-background-light"
          >
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 flex items-center">
                <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                  <div>
                    <p class="text-sm font-medium text-primary truncate">{{ project.name }}</p>
                    <p class="mt-2 flex items-center text-sm text-gray-500">
                      <UserGroupIcon
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span
                        v-if="project.client"
                        class="truncate"
                      >{{project.client.name }}</span>
                      <span
                        v-else
                        class="truncate"
                      >
                        ---------
                      </span>

                    </p>
                  </div>

                  <div class="hidden md:block">
                    <div>
                      <p
                        v-if="project.deadline"
                        class="mt-2 flex items-center text-sm text-gray-500"
                      >
                        <ClockIcon
                          class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span class="truncate">{{ new Date(project.deadline).toLocaleString() }}</span>
                      </p>

                      <!-- Add more datapoints here -->
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <ChevronRightIcon
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </section>

    <!-- No Projects yet -->
    <section
      v-else
      class="base-container"
    >
      <EmptyState
        class=""
        text="No projects have been created yet, start by creating a new project."
        buttonText="New project"
        @buttonClick="$router.push('/projects/create')"
      />
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  ChevronRightIcon,
  UserGroupIcon,
  ClockIcon,
} from '@heroicons/vue/solid';
import PageHeading from '@/components/PageHeading.vue';
import EmptyState from '@/components/EmptyState.vue';

export default {
  name: 'projects',
  components: {
    EmptyState,
    PageHeading,
    ChevronRightIcon,
    UserGroupIcon,
    ClockIcon,
  },
  computed: mapGetters({
    users: 'users',
    projects: 'projects/all',
  }),
  methods: {
    ...mapActions({
      me: 'me',
      getAll: 'projects/get_all',
    }),
  },
  async mounted() {
    console.log('mount projects');
    if (!this.user) await this.me();
    // restrict access
    this.checkUserRoles('user');
    await this.getAll();
  },
};
</script>
