<template>
  <div class="bg-background-light ">
    <PageHeading :title="`Edit project: ${project.name}`">
    </PageHeading>

    <!-- Tabs -->
    <div
      id="tabs"
      class="fixed top-16 z-10 bg-background-dark"
    >

      <div class="block">
        <div class="border-b border-gray-200">
          <nav
            class="-mb-px flex"
            aria-label="Tabs"
            v-if="project && project.templates"
          >
            <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
            <router-link
              :to="`/projects/${$route.params.projectId}/general`"
              class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-full py-4 px-1 text-center border-b-2 font-medium text-sm"
              :class="{ 'border-primary text-primary': /general/.test($route.path)}"
            >
              General
            </router-link>
            <router-link
              :to="`/projects/${$route.params.projectId}/images`"
              class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-full py-4 px-1 text-center border-b-2 font-medium text-sm"
              :class="{ 'border-primary text-primary': /images/.test($route.path)}"
            >
              Images
            </router-link>
            <router-link
              v-for="template of project.templates"
              :key="template._id"
              :to="`/projects/${$route.params.projectId}/fragments/${template._id}`"
              class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-full py-4 px-1 text-center border-b-2 font-medium text-sm"
              :class="{ 'border-primary text-primary': new RegExp(template._id).test($route.path)}"
            >
              {{template.name}}
            </router-link>
            <router-link
              :to="`/projects/${$route.params.projectId}/fragments/add`"
              class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-full py-4 px-1 text-center border-b-2 font-medium text-sm"
              :class="{ 'border-primary text-primary': /fragments\/add/.test($route.path)}"
            >
              <span class="hidden sm:block">Add fragments</span>
              <PlusIcon class="w-5 h-5 mx-auto sm:hidden" />
            </router-link>

          </nav>
        </div>
      </div>
    </div>

    <router-view class="relative top-12"></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { PlusIcon } from '@heroicons/vue/outline';
import PageHeading from '@/components/PageHeading.vue';

export default {
  name: 'projectsEdit',
  components: {
    PageHeading,
    PlusIcon,
  },
  computed: mapGetters({
    project: 'projects/single',
  }),
  methods: {
    ...mapActions({
      me: 'me',
      get_project: 'projects/get_single',
    }),
    navigateTo(event) {
      this.$router.push(event.target.value);
    },
  },
  async mounted() {
    console.log('mount edit project');
    if (!this.user) await this.me();
    // restrict access
    this.checkUserRoles('user');
    // fetch the current project in the state
    await this.get_project(this.$route.params.projectId);
  },
};
</script>
