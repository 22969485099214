import envalidate from '@siliconminds/envalidate';
import Api from '@siliconminds/api';
import { required } from '@siliconminds/validation/validators';

envalidate('VUE_APP_API_URL');

const api = new Api(process.env.VUE_APP_API_URL);

const endpoint = 'projects';

export default {
  namespaced: true,
  state: {
    pagination: {
      limit: 25,
      prev: false,
      page: 1,
      next: false,
      last: false,
    },
    fields: [
      // {
      //   label: 'Image',
      //   key: 'image',
      //   type: 'image',
      //   editable: true,
      //   validators: {},
      // },
      {
        label: 'Name',
        key: 'name',
        type: 'text',
        editable: true,
        validators: { required },
      },
      {
        label: 'Desc',
        key: 'desc',
        type: 'textarea',
        editable: true,
        validators: {},
      },
      {
        label: 'Client',
        key: 'client',
        type: 'select',
        options: [], // todo get all contacts
        optionKey: '_id',
        optionLabel: 'name',
        editable: true,
        validators: { required },
      },
      {
        label: 'Start',
        key: 'start',
        type: 'date',
        editable: true,
        validators: { required },
      },
      {
        label: 'Deadline',
        key: 'deadline',
        type: 'date',
        editable: true,
        validators: { required },
      },
      // {
      //   label: 'Done',
      //   key: 'done',
      //   type: 'checkbox',
      //   editable: true,
      //   validators: {},
      // },
      {
        label: 'Budget',
        key: 'budget',
        type: 'money',
        editable: true,
        validators: {},
      },
    ],
    all: [],
    single: {},
  },
  getters: {
    fields: (state) => state.fields,

    all: (state) => state.all,
    single: (state) => state.single,

    pagination: (state) => state.pagination,
  },
  actions: {
    async set_page({ dispatch, commit, getters }, page) {
      const { pagination } = getters;
      if ((page < 0 && pagination.page > 1) || (page > 0 && pagination.page < pagination.last)) {
        commit('setPage', page);
      }
      dispatch('get_all');
    },

    async get_all({ commit, getters, dispatch }, payload = {}) {
      const { pagination } = getters;
      const res = await api.get(endpoint, {
        query: {
          ...payload,
          _page: pagination.page,
          _limit: pagination.limit,
        },
      });
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      commit('setPagination', res.pagination);
      return commit('setAll', res.data);
    },
    async get_single({ commit, dispatch }, id) {
      const res = await api.get(`${endpoint}/${id}`);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      return commit('setSingle', res.data);
    },
    async post({ commit, dispatch }, payload) {
      const res = await api.post(endpoint, payload);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      commit('push', res.data);
      dispatch('pushNotification', { type: 'success', title: 'Project created!' }, { root: true });
      return res.data;
    },
    async post_image({ commit, dispatch, state }, image) {
      const formData = new FormData();
      formData.append('files', image);
      const headers = { 'Content-Type': 'multipart/form-data' };
      const res = await api.post(`${endpoint}/${state.single._id}/images`, formData, { headers });
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      commit('pushImage', res.data);
      dispatch('pushNotification', { type: 'success', title: 'Image uploaded' }, { root: true });
    },
    async post_fragment({ commit, state, dispatch }, templateId = '') {
      const res = await api.post(`${endpoint}/${state.single._id}/fragments/${templateId}`, {});
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      commit('pushFragment', res.data);
      dispatch('pushNotification', { type: 'success', title: 'Fragment added!' }, { root: true });
      return res.data;
    },
    async put({ commit, dispatch }, payload) {
      const res = await api.put(`${endpoint}/${payload._id}`, payload);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      dispatch('pushNotification', { type: 'success', title: 'Project saved!' }, { root: true });
      return commit('update', payload);
    },
    async sync_tasks({ state, dispatch }, id) {
      const res = await api.put(`${endpoint}/${state.single._id}/fragments/${id}/sync`);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      dispatch('pushNotification', { type: 'success', title: 'Tasks synced!' }, { root: true });
    },
    async delete({ commit, getters, dispatch }, payload) {
      const res = await api.delete(`${endpoint}/${payload._id}`);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      commit('delete', payload);
      dispatch('pushNotification', { type: 'success', title: 'Project archived!' }, { root: true });
      if (getters.pagination.page > 1 && getters.all.length < 1) return dispatch('set_page', -1);
      return true;
    },
    async delete_fragment({ commit, state, dispatch }, fragmentId) {
      const res = await api.delete(`${endpoint}/${state.single._id}/fragments/${fragmentId}`);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      commit('deleteFragment', fragmentId);
      dispatch('pushNotification', { type: 'success', title: 'Fragment removed!' }, { root: true });
      return true;
    },
    async delete_image({ commit, state, dispatch }, imageId) {
      const res = await api.delete(`${endpoint}/${state.single._id}/images/${imageId}`);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      commit('deleteImage', imageId);
      dispatch('pushNotification', { type: 'success', title: 'Image removed!' }, { root: true });
      return true;
    },
  },
  mutations: {
    setAll: (state, items) => { state.all = items; },
    setSingle: (state, item) => { state.single = item; },
    push: (state, item) => { state.all.push(item); },

    update: (state, item) => { state.all[state.all.findIndex((el) => el._id.toString() === item._id.toString())] = item; },
    delete: (state, item) => { state.all = state.all.filter(el => el._id.toString() !== item._id.toString()); },

    pushImage: (state, item) => { state.single.images.push(item); },
    deleteImage: (state, id) => { state.single.images = state.single.images.filter(el => el._id.toString() !== id.toString()); },

    pushFragment: (state, fragment) => { state.single.templates.push(fragment); },
    deleteFragment: (state, id) => { state.single.templates = state.single.templates.filter(el => el._id.toString() !== id.toString()); },

    setPagination: (state, pagination) => { state.pagination = pagination; },
    setPage: (state, page) => { state.pagination.page += page; },
  },
};
