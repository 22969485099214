<template>
  <div>
    <div class=" text-left mr-4">
      <div class="flex flex-col justify-end">
        <p class="z-10 text-xl">Daan Gysels</p>
        <h1 class="z-10 font-bold text-4xl">Maatwerk</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    size: { type: String, default: 'md' },
  },
};
</script>
