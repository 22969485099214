<template>
  <div class="bg-background-light ">

    <TitleDesc
      class="base-container"
      title="Images"
      desc="Here you can add, remove and view images for this project"
    >
      <BaseButton
        class="sm:col-start-2"
        @click="$refs.files.click()"
        v-if="project.images && project.images.length > 0"
      >
        Upload image
      </BaseButton>
    </TitleDesc>

    <div class="base-container">
      <section
        v-if="project.files && project.files.length > 0"
        class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-4"
      >

        <div
          id="image-tile"
          class="cursor-pointer relative "
          v-for="image in project.files"
          :key="image.url"
          @click="$router.push(`/images/${image._id}`)"
        >
          <img
            class="h-full w-full object-cover object-center shadow-lg rounded-md"
            :src="imageUrl(image)"
          />
          <div
            id="image-controls"
            class="invisible absolute top-2 right-2 rounded-full flex justify-end bg-gray-50 bg-opacity-50 p-2 text-black hover:text-gray-600"
          >
            <TrashIcon
              @click.stop="removeImage(image)"
              class="w-6 h-6 "
            />
          </div>

        </div>

      </section>

      <EmptyState
        v-else
        class=""
        text="No images added yet, start by uploading one"
        buttonText="Upload image"
        @buttonClick="$refs.files.click()"
      />
    </div>

    <input
      class="hidden"
      ref="files"
      type="file"
      name="image"
      id="image"
      @change="uploadFile"
    >

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { TrashIcon } from '@heroicons/vue/outline';
import TitleDesc from '@/components/TitleDesc.vue';
import EmptyState from '@/components/EmptyState.vue';

export default {
  name: 'ProjectsEditImages',
  data: () => ({}),
  components: {
    TitleDesc,
    EmptyState,
    TrashIcon,
  },
  computed: {
    project: {
      get() {
        const project = this.$store.state.projects.single;
        if (!project.images) project.images = [];
        return project;
      },
      set(value) {
        this.$store.commit('projects/setSingle', value);
      },
    },
  },
  methods: {
    ...mapActions({
      post_image: 'projects/post_image',
      delete_image: 'projects/delete_image',
      get_project: 'projects/get_single',
    }),
    imageUrl(image) {
      return `${process.env.VUE_APP_API_URL}/${image.slug}`;
    },
    async uploadFile() {
      await this.post_image(this.$refs.files.files[0]);
    },
    async removeImage(image) {
      await this.delete_image(image._id);
    },
  },
  async mounted() {
    await this.get_project(this.$route.params.projectId);
  },
};
</script>

<style scoped>
#image-tile {
  aspect-ratio: 1;
}
#image-tile:hover #image-controls {
  @apply visible;
}
</style>
