<template>
  <div class="bg-background-light ">
    <div>

      <!-- Fragment page -->
      <TitleDesc
        class="base-container"
        title="Edit this fragment"
        desc="Here you can edit the values of the fragment"
      >
        <BaseButton
          type="danger"
          @click="removeFromProject()"
        >Remove from project</BaseButton>
        <BaseButton
          type="primary"
          @click="$router.push(`/templates/${$route.params.fragmentId}/general`)"
        >Configure</BaseButton>
        <BaseButton
          v-if="template.fields && template.fields.length > 0"
          type="primary"
          @click="syncTasks() "
        >Sync tasks</BaseButton>
        <BaseButton
          class=""
          @click="save()"
        >Save</BaseButton>

      </TitleDesc>

      <!-- Edit fields -->
      <section
        v-if="template.fields && template.fields.length > 0"
        class="base-container base-card"
      >
        <div>
          <div class="mb-4">
            <h2 class="text-xl">{{template.name}}</h2>
            <p class="text-gray-600">
              {{template.desc}}
            </p>
          </div>
          <div
            v-if="project && project.content"
            class="space-y-2"
          >
            <DynamicField
              v-for="field in template.fields"
              :key="field.key"
              :field="field"
              v-model="project.content[field._id]"
            />
          </div>
          <div class="grid gap-2 grid-cols-2 sm:grid-cols-4 md:flex md:justify-end mt-4 justify-end w-full">
            <BaseButton
              type="danger"
              @click="removeFromProject()"
            >Remove fragment</BaseButton>
            <BaseButton
              type="primary"
              @click="$router.push(`/templates/${$route.params.fragmentId}/general`)"
            >Configure</BaseButton>
            <BaseButton
              v-if="template.fields && template.fields.length > 0"
              type="primary"
              @click="syncTasks() "
            >Sync tasks</BaseButton>
            <BaseButton
              class=""
              @click="save()"
            >Save</BaseButton>

          </div>
        </div>
      </section>
      <section
        v-else
        class="mx-8 p-4 border-4 border-dashed border-gray-300 rounded-2xl flex justify-between items-center"
      >
        <p class="text-lg text-gray-500">No fields have been added yet, go configure the fragment first.</p>
        <BaseButton
          @click="$router.push(`/templates/${$route.params.fragmentId}/general`)"
          type="primary"
        >Configure</BaseButton>
      </section>
    </div>
    <ModalConfirm ref="confirmation" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DynamicField from '@/components/DynamicField.vue';
import TitleDesc from '@/components/TitleDesc.vue';
import ModalConfirm from '@/components/ModalConfirm.vue';

export default {
  name: 'ProjectsEditGeneral',
  data: () => ({}),
  components: {
    DynamicField,
    TitleDesc,
    ModalConfirm,
  },
  computed: {
    ...mapGetters({
      template: 'templates/single',
      project: 'projects/single',
    }),
    project: {
      get() {
        const project = this.$store.state.projects.single;
        if (!project.content) project.content = {};
        return project;
      },
      set(value) {
        this.$store.commit('projects/setSingle', value);
      },
    },
  },
  watch: {
    '$route.path': async function () {
      if (this.$route.params.fragmentId) await this.get_template(this.$route.params.fragmentId);
    },
  },
  methods: {
    ...mapActions({
      put_template: 'templates/put',
      get_template: 'templates/get_single',
      put_project: 'projects/put',
      get_project: 'projects/get_single',
      delete_fragment: 'projects/delete_fragment',
      sync_tasks: 'projects/sync_tasks',
    }),
    async removeFromProject() {
      const remove = await this.$refs.confirmation.ask(
        'Remove this fragment?',
        'Be carefull, as this is a potentially destructive action that can not be restored.',
      );
      if (remove) {
        await this.delete_fragment(this.$route.params.fragmentId);
        this.$router.push(`/projects/${this.$route.params.projectId}/general`);
      }
    },
    async syncTasks() {
      const sync = await this.$refs.confirmation.ask(
        'Sync tasks of this fragment?',
        'This will make sure all tasks in the fragment are created.',
      );
      if (sync) await this.sync_tasks(this.$route.params.fragmentId);
    },
    async save() {
      await this.put_project(this.project);
    },
  },
  async mounted() {
    console.log('mount edit fragment');
    await this.get_template(this.$route.params.fragmentId);
    await this.get_project(this.$route.params.projectId);
    if (!this.project.content) this.project.content = {};
  },
};
</script>
