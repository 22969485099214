<template>
  <div class="bg-background-light ">

    <TitleDesc
      class="base-container mb-2"
      title="Edit tasks"
      desc="Here you can add and edit the tasks associated with this template. <br>Tasks can use data from the template fields to make them dynamic"
    >
      <BaseButton
        type="primary"
        v-if="template.tasks && template.tasks.length > 0"
        @click="popupConfirmSync = true"
      >Sync tasks</BaseButton>
      <BaseButton
        type="primary"
        v-if="template.tasks && template.tasks.length > 0"
        @click="new_task()"
      >New task</BaseButton>
    </TitleDesc>

    <div
      v-if="template.tasks && template.tasks.length > 0"
      class="sm:rounded-md"
    >
      <ul
        role="list"
        class="divide-y divide-gray-200"
      >
        <li
          class="bg-background"
          v-for="(task, index) in template.tasks"
          :key="index"
        >
          <router-link
            :to="`/templates/${$route.params.templateId}/tasks/${task._id}`"
            class="block hover:bg-background-light"
          >
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 flex items-center">
                <div class="min-w-0 flex-1 md:px-6 md:gap-4">
                  <div>
                    <p class="text-sm font-medium text-primary truncate">{{ task.name }}</p>
                    <p class="mt-2 flex items-center text-sm text-gray-500">
                      <span class="truncate">{{task.desc}}</span>
                    </p>
                  </div>

                </div>
              </div>
              <div>
                <ChevronRightIcon
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>

    <div
      v-else
      class="base-container"
    >
      <EmptyState
        text="No tasks yet. Create a new task to get started."
        buttonText="New task"
        @buttonClick="new_task()"
      />
    </div>

    <BaseModal
      :open="popupConfirmSync"
      @close="popupConfirmSync = false"
      title="Sync tasks?"
    >
      <p class="text-gray-600 mb-2">Syncing tasks will make sure that every project using this template has all the tasks described in this template.</p>
      <div class="flex w-full justify-end space-x-2 pt-2">
        <BaseButton
          type="danger"
          class="flex-1"
          @click.prevent="popupConfirmSync = false"
        >
          cancel
        </BaseButton>
        <BaseButton
          class="flex-1"
          @click.prevent="syncTasks()"
        >
          sync
        </BaseButton>
      </div>
    </BaseModal>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ChevronRightIcon } from '@heroicons/vue/solid';
import EmptyState from '@/components/EmptyState.vue';
import TitleDesc from '@/components/TitleDesc.vue';

export default {
  name: 'TemplatesEditTasks',
  data: () => ({
    popupConfirmSync: false,
  }),
  components: {
    ChevronRightIcon,
    EmptyState,
    TitleDesc,
  },
  computed: mapGetters({
    template: 'templates/single',
    fields: 'tasks/fields',
  }),
  methods: {
    ...mapActions({
      create_task: 'templates/post_task',
      sync_tasks: 'templates/sync_tasks',
      get_template: 'templates/get_single',
    }),
    async new_task() {
      const task = await this.create_task(this.$route.params.templateId);
      this.$router.push(
        `/templates/${this.$route.params.templateId}/tasks/${task._id}`,
      );
    },
    async syncTasks() {
      await this.sync_tasks(this.$route.params.templateId);
      this.popupConfirmSync = false;
    },
  },
  async mounted() {
    await this.get_template(this.$route.params.templateId);
  },
};
</script>
