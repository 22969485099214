import envalidate from '@siliconminds/envalidate';
import Api from '@siliconminds/api';
import { number, email } from '@siliconminds/validation/validators';

envalidate('VUE_APP_API_URL');

const api = new Api(process.env.VUE_APP_API_URL);

const endpoint = 'contacts';

export default {
  namespaced: true,
  state: {
    pagination: {
      limit: 10000,
      prev: false,
      page: 1,
      next: false,
      last: false,
    },
    fields: [
      {
        label: 'Name',
        key: 'name',
        type: 'text',
        editable: true,
        validators: {},
      },
      {
        label: 'Email',
        key: 'email',
        type: 'email',
        editable: true,
        validators: { email },
      },
      {
        label: 'Phone',
        key: 'phone',
        type: 'text',
        editable: true,
        validators: {},
      },
      {
        label: 'Address',
        key: 'address',
        type: 'object',
        editable: true,
        validators: {},
        fields: [
          {
            label: 'Street',
            key: 'street',
            type: 'text',
            editable: true,
            validators: {},
          },
          {
            label: 'House number',
            key: 'house_number',
            type: 'number',
            editable: true,
            validators: { number },
          },
          {
            label: 'Appartement',
            key: 'appartement',
            type: 'text',
            editable: true,
            validators: {},
          },
          {
            label: 'City',
            key: 'city',
            type: 'text',
            editable: true,
            validators: {},
          },
          {
            label: 'Zip',
            key: 'zip',
            type: 'number',
            editable: true,
            validators: { number },
          },
        ],
      },
    ],
    all: [],
    single: {},
  },
  getters: {
    fields: (state) => state.fields,

    all: (state) => state.all,
    single: (state) => state.single,

    pagination: (state) => state.pagination,
  },
  actions: {
    async set_page({ dispatch, commit, getters }, page) {
      const { pagination } = getters;
      if ((page < 0 && pagination.page > 1) || (page > 0 && pagination.page < pagination.last)) {
        commit('setPage', page);
      }
      dispatch('get_all');
    },

    async get_all({ commit, getters, dispatch }, payload = {}) {
      const { pagination } = getters;
      const res = await api.get(endpoint, {
        query: {
          ...payload,
          _page: pagination.page,
          _limit: pagination.limit,
        },
      });
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      commit('setPagination', res.pagination);
      return commit('setAll', res.data);
    },
    async get_single({ commit, dispatch }, id) {
      const res = await api.get(`${endpoint}/${id}`);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      return commit('setSingle', res.data);
    },
    async post({ commit, dispatch }, payload) {
      const res = await api.post(endpoint, payload);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      dispatch('pushNotification', { type: 'success', title: 'Contact created!' }, { root: true });
      return commit('push', res.data);
    },
    async put({ commit, dispatch }, payload) {
      const res = await api.put(`${endpoint}/${payload._id}`, payload);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      dispatch('pushNotification', { type: 'success', title: 'Contact saved!' }, { root: true });
      return commit('update', payload);
    },
    async delete({ commit, getters, dispatch }, payload) {
      const res = await api.delete(`${endpoint}/${payload._id}`);
      if (!res.success) return dispatch('pushNotification', { type: 'error', title: res.error }, { root: true });
      dispatch('pushNotification', { type: 'success', title: 'Contact archived!' }, { root: true });
      commit('delete', payload);
      if (getters.pagination.page > 1 && getters.all.length < 1) return dispatch('set_page', -1);
      return true;
    },
  },
  mutations: {
    setAll: (state, items) => { state.all = items; },
    setSingle: (state, item) => { state.single = item; },
    push: (state, item) => { state.all.push(item); },
    update: (state, item) => { state.all[state.all.findIndex((el) => el._id.toString() === item._id.toString())] = item; },
    delete: (state, item) => { state.all = state.all.filter(el => el._id.toString() !== item._id.toString()); },

    setPagination: (state, pagination) => { state.pagination = pagination; },
    setPage: (state, page) => { state.pagination.page += page; },
  },
};
