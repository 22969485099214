<template>
  <div class="bg-background-light">
    <PageHeading title="Home" />

    <section class="relative -top-4 bg-white shadow w-full border-b border-background-dark py-4">
      <div class="flex items-center justify-start px-4 sm:px-6 lg:px-12 ">
        <img
          class="rounded-full h-10 w-10 sm:h-16 sm:w-16"
          :src="user.avatar"
          alt=""
        />
        <h1 class="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
          <span>{{greeting}}</span>
          <span>{{user.firstname}}</span>
        </h1>
      </div>
    </section>

    <section class="base-container">
      <h2 class="text-lg leading-6 font-medium text-gray-900">Overview</h2>
      <div class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        <!-- Card -->
        <div
          v-for="card in cards"
          :key="card.name"
          class="bg-white overflow-hidden shadow rounded-lg"
        >
          <div class="p-5">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <component
                  :is="card.icon"
                  class="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-5 w-0 flex-1">
                <dl>
                  <dt class="text-sm font-medium text-gray-500 truncate">
                    {{ card.name }}
                  </dt>
                  <dd>
                    <div class="text-lg font-medium text-gray-900">
                      {{ card.amount }}
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-5 py-3">
            <div class="text-sm">
              <router-link
                :to="card.href"
                class="font-medium text-cyan-700 hover:text-cyan-900"
              > View all </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="base-container">
      <h2 class="text-lg leading-6 font-medium text-gray-900">E-Z flows</h2>
      <EmptyState
        text="Coming soon!"
        class="mt-2"
      />
    </section>

    <section class="base-container">
      <h2 class="text-lg leading-6 font-medium text-gray-900">Upcomming deadlines</h2>
      <DynamicTable
        class=""
        v-if="upcomming && upcomming.length > 0"
        :fields="taskFields"
        :data="upcomming"
        :buttons="false"
        :selectable="false"
        @clickElement="$emit('open_task', $event)"
      />
      <EmptyState
        v-else
        text="No tasks yet, go create some!"
        buttonText="Create task"
        @buttonClick="$router.push('/tasks/list')"
        class="mt-2"
      />
    </section>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  BriefcaseIcon,
  ClipboardListIcon,
  ClockIcon,
} from '@heroicons/vue/outline';
import Api from '@siliconminds/api';
import EmptyState from '@/components/EmptyState.vue';
import PageHeading from '@/components/PageHeading.vue';
import DynamicTable from '@/components/DynamicTable.vue';

const api = new Api(process.env.VUE_APP_API_URL);

export default {
  name: 'home',
  components: {
    PageHeading,
    BriefcaseIcon,
    EmptyState,
    DynamicTable,
  },
  data: () => ({
    stats: {
      projects: 0,
      tasks: 0,
      total_ms: 0,
    },
    upcomming: [],
    showTaskFields: ['dynamicName', 'dynamicDesc', 'deadline', 'project'],
  }),
  computed: {
    ...mapGetters({
      user: 'user',
      projects: 'projects/all',
    }),
    taskFields() {
      return this.$store.state.tasks.fields.filter((f) => this.showTaskFields.includes(f.key));
    },
    greeting() {
      const currentHour = new Date().getHours();
      if (currentHour < 6) return 'Good night ';
      if (currentHour < 12) return 'Good morning ';
      if (currentHour < 18) return 'Good afternoon ';
      if (currentHour < 23) return 'Good evening ';
      return 'Good night ';
    },
    cards() {
      const minutesWorked = Math.floor(this.stats.total_ms / 1000 / 60);
      const hoursWorked = Math.floor(minutesWorked / 60);
      return [
        {
          name: 'Active projects',
          icon: BriefcaseIcon,
          amount: this.stats.projects,
          href: '/projects',
        },
        {
          name: 'Open tasks',
          icon: ClipboardListIcon,
          amount: this.stats.tasks,
          href: '/tasks/list',
        },
        {
          name: 'Hours worked this week',
          icon: ClockIcon,
          amount: `${hoursWorked}:${minutesWorked % 60}`,
          href: '/',
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      me: 'me',
      get_projects: 'projects/get_all',
    }),
    async getStats() {
      const { data } = await api.get('analytics/stats');
      this.stats = data;
    },
    async getUpcomming() {
      const { data } = await api.get('analytics/deadlines');
      this.upcomming = data;
      console.log(this.upcomming);
    },
  },
  async mounted() {
    if (!this.user) await this.me();
    console.log('mount home');
    // restrict access
    this.checkUserRoles('user');
    await this.getStats();
    await this.getUpcomming();
  },
};
</script>
