<template>
  <div>

    <div class="base-container">

      <TitleDesc
        class=""
        title="Images"
        desc="Here you can add, remove and view images for this project"
      >
        <BaseButton
          v-if="task.images && task.images.length > 0"
          @click="$refs.files.click()"
        >
          Upload image
        </BaseButton>
      </TitleDesc>

      <section
        v-if="task.images && task.images.length > 0"
        class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-6 gap-4 pb-12"
      >

        <div
          id="image-tile"
          class="cursor-pointer relative "
          v-for="image in task.images"
          :key="image.slug"
          @click="$router.push(`/images/${image._id}`)"
        >
          <img
            class="h-full w-full object-cover object-center shadow-lg rounded-md"
            :src="imageUrl(image)"
          />
          <div
            id="image-controls"
            class="invisible absolute top-2 right-2 rounded-full flex justify-end bg-gray-50 bg-opacity-50 p-2 text-black hover:text-gray-600"
          >
            <TrashIcon
              @click.stop="removeImage(image)"
              class="w-6 h-6 "
            />
          </div>

        </div>

      </section>

      <EmptyState
        v-else
        class="mt-4"
        text="No images added yet, start by uploading one"
        buttonText="Upload image"
        @buttonClick="$refs.files.click()"
      />
    </div>

    <input
      class="hidden"
      ref="files"
      type="file"
      name="image"
      id="image"
      @change="uploadFile"
    >

  </div>
</template>

<script>
import { TrashIcon } from '@heroicons/vue/outline';
import { mapActions } from 'vuex';
import EmptyState from '@/components/EmptyState.vue';
import TitleDesc from '@/components/TitleDesc.vue';

export default {
  name: 'images',
  components: {
    EmptyState,
    TrashIcon,
    TitleDesc,
  },
  computed: {
    task: {
      get() {
        const task = this.$store.state.tasks.single;
        if (!task.images) task.images = [];
        return task;
      },
      set(value) {
        this.$store.commit('tasks/setSingle', value);
      },
    },
  },
  methods: {
    ...mapActions({
      get_task: 'tasks/get_single',
      post_image: 'tasks/post_image',
      delete_image: 'tasks/delete_image',
    }),
    // images
    imageUrl(image) {
      return `${process.env.VUE_APP_API_URL}/${image.slug}`;
    },
    async uploadFile() {
      await this.post_image(this.$refs.files.files[0]);
    },
    async removeImage(image) {
      await this.delete_image(image._id);
    },
  },
  async mounted() {
    await this.get_task(this.$route.params.taskId);
  },
};
</script>

<style scoped>
#image-tile {
  aspect-ratio: 1;
}
#image-tile:hover #image-controls {
  @apply visible;
}
</style>
