<template>
  <div
    id="pageHeading"
    class="fixed top-0 lg:pr-64 w-full z-10 bg-background-dark border-b border-gray-200 py-5 print:hidden"
  >
    <div class="px-6 flex items-center justify-between lg:space-x-4">
      <!-- Mobile sidebar toggle -->
      <button
        @click.stop="menus_toggle('mobile_menu')"
        type="button"
        class="lg:hidden pr-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-none"
      >
        <span class="sr-only">Open sidebar</span>
        <MenuAlt1Icon
          aria-hidden="true"
          class="h-6 w-6"
        />
      </button>

      <!-- Back button -->
      <button
        @click="$router.go(-1)"
        class="hidden lg:flex items-center space-x-1 text-gray-500 hover:text-gray-700 "
      >
        <ArrowLeftIcon class="w-4 h-4" />
        <span class="">Back</span>
      </button>

      <div class="flex-1">
        <h1 class="text-2xl font-medium leading-6 text-gray-900 lg:border-l border-gray-400 lg:pl-5">
          {{ title }}
        </h1>
      </div>
      <div class=" flex mt-0 sm:ml-4">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ArrowLeftIcon, MenuAlt1Icon } from '@heroicons/vue/outline';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  methods: mapActions(['menus_toggle']),
  components: {
    ArrowLeftIcon,
    MenuAlt1Icon,
  },
};
</script>
