<template>
  <div class="absolute inset-0 min-h-screen bg-white flex">
    <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <router-link to="/">
            <BaseLogo size="xl" />
          </router-link>

          <h2 class="mt-6 text-xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div class="mt-8">
          <div>
            <div>
              <p class="text-sm font-medium text-gray-700">
                Sign in with
              </p>

              <div class="mt-1 grid grid-cols-2 gap-3">
                <div>
                  <button
                    @click="set_method('password')"
                    :class="{'bg-gray-100 hover:bg-gray-100': $route.query.method === 'password'}"
                    class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span class="sr-only">Sign in with password</span>
                    <KeyIcon class="w-5 h-5" />
                  </button>
                </div>

                <div>
                  <button
                    @click="set_method('magiclink')"
                    :class="{'bg-gray-100 hover:bg-gray-100': $route.query.method === 'magiclink'}"
                    class="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                  >
                    <span class="sr-only">Sign in with magiclink</span>
                    <LinkIcon class="w-5 h-5" />
                  </button>
                </div>

              </div>
            </div>

            <div class="mt-6 relative">
              <div
                class="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div class="w-full border-t border-gray-300" />
              </div>
              <div class="relative flex justify-center text-sm">
              </div>
            </div>
          </div>

          <div class="mt-6">
            <form
              @submit.prevent="submit()"
              class="space-y-6"
            >

              <div>
                <label
                  for="email"
                  class="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div class="mt-1">
                  <input
                    v-model="object.email"
                    id="email"
                    name="email"
                    type="email"
                    autocomplete="email"
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                    required
                  >
                </div>
              </div>

              <div
                v-if="$route.query.method === 'password'"
                class="space-y-1"
              >
                <label
                  for="password"
                  class="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div class="mt-1">
                  <input
                    v-model="object.password"
                    id="password"
                    name="password"
                    type="password"
                    autocomplete="current-password"
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
                    required
                  >
                </div>
              </div>

              <div class="flex items-center justify-between">
                <div class="text-sm">
                  <router-link
                    to="/auth/reset-password"
                    class="font-medium text-primary hover:text-opacity-70 hover:underline"
                  >
                    Forgot your password?
                  </router-link>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-opacity-70 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                >
                  Sign in
                </button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="hidden lg:block relative w-0 flex-1">
      <img
        class="absolute inset-0 h-full w-full object-cover"
        src="https://images.unsplash.com/photo-1617873228868-f64a54e91a01?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80"
        alt=""
      >
    </div>
  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { KeyIcon, LinkIcon } from '@heroicons/vue/outline';

export default {
  name: 'login',
  components: {
    KeyIcon,
    LinkIcon,
  },
  data: () => ({
    object: {
      email: '',
      password: '',
    },
  }),
  methods: {
    ...mapActions(['login', 'magiclink', 'me']),
    submit() {
      const options = {
        data: this.object,
        redirect: this.$route.query.redirect || '/',
      };
      if (this.$route.query.method === 'password') this.login(options);
      else this.magiclink(options);
    },
    set_method(method) {
      if (this.$route.query.method === method) return;
      this.$router.replace({ query: { method } });
    },
  },
  computed: mapGetters(['user']),
  async mounted() {
    if (!this.$route.query.method) {
      this.set_method('password');
      this.$forceUpdate();
    }
    await this.me();
    if (this.user) return this.$router.push(this.$route.query.redirect || '/');
    return true;
  },
};
</script>
