<template>
  <div class="grid grid-cols-8 gap-4">
    <BaseInput
      class="col-span-7"
      type="number"
      @update:modelValue="updateValue($event)"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
      :modelValue="value"
      :name="name"
      :label="label"
      :error="error"
    />
    <BaseSelect
      class=""
      @update:modelValue="updateSuffix($event)"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
      :modelValue="suffix"
      :options="suffixes"
      :error="error"
      label="suffix"
      name="suffix"
      optionKey="key"
      optionLabel="label"
    />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: [String, Number],
    name: { type: String, required: true },
    type: { type: String, required: true },
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    error: { type: String, default: '' },
    hint: { type: String, default: '' },
  },
  data: () => ({
    value: ' ',
    suffix: '.',
  }),
  watch: {
    modelValue() {
      if (!this.modelValue) return;
      const [value, suffix] = this.modelValue.split(' ');
      this.value = value;
      this.suffix = suffix || '.';
    },
  },
  methods: {
    updateValue(value) {
      this.value = value;
      this.notifyUpdate();
    },
    updateSuffix(suffix) {
      this.suffix = suffix;
      this.notifyUpdate();
    },
    notifyUpdate() {
      this.$emit('update:modelValue', `${this.value} ${this.suffix}`);
    },
  },
  computed: {
    unit() {
      return {
        meter: 'm',
        gram: 'g',
        liter: 'l',
      }[this.type];
    },
    suffixes() {
      return [
        { key: `m${this.unit}`, label: `m${this.unit}` },
        { key: `c${this.unit}`, label: `c${this.unit}` },
        { key: `${this.unit}`, label: `${this.unit}` },
        { key: `k${this.unit}`, label: `k${this.unit}` },
      ];
    },
  },
  mounted() {
    if (!this.modelValue) return;
    const [value, suffix] = this.modelValue.split(' ');
    this.value = value;
    this.suffix = suffix || '.';
  },
};
</script>
