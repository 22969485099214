<template>
  <div class="bg-background-light ">

    <div class="base-container mb-2 flex flex-col md:flex-row justify-between">
      <div class="">
        <h2 class="text-xl">Edit task</h2>
        <p class="text-gray-600">
          Here you can edit this task. <br>
          You can make the title and description dynamic by using the fields from this template.
          To use a field, surround its key with: ${ and }.</p>
        <p
          v-if="allowedFields && allowedFields.length > 0"
          class="text-gray-600 mt-2"
        >Available keys are:
          <span
            class=""
            v-for="(field, index) in allowedFields"
            @click="copy(field.key)"
            :key="field._id"
          >
            <span v-if="index == allowedFields.length - 1 && allowedFields.length > 1"> and </span>
            <span v-else-if="index > 0">, </span>
            <span class="text-primary hover:text-lg cursor-pointer hover:text-secondary hover:underline">{{ `${field.key}` }}</span>
          </span>
          (click to copy)
        </p>
      </div>
      <div class="hidden md:block md:min-w-min gap-2 grid-cols-1 sm:grid-cols-2 md:space-x-2 flex-shrink-0 mt-4 md:mt-0">
        <BaseButton
          type="danger"
          @click="popupConfirmDelete = true"
        >Archive</BaseButton>
        <BaseButton
          type="primary"
          @click="popupConfirmSync = true"
        >Sync</BaseButton>
        <BaseButton
          type="primary"
          @click="save()"
        >Save</BaseButton>
      </div>
    </div>

    <div class="base-container base-card">
      <DynamicForm
        :fields="fields.filter(el => !illegalFields.includes(el.key))"
        v-model="task"
        @submit="save()"
      >
        <BaseButton
          type="danger"
          @click="popupConfirmDelete = true"
          class="sm:col-start-1 row-start-3 sm:row-start-1"
        >Archive</BaseButton>
        <BaseButton
          type="primary"
          @click="popupConfirmSync = true"
        >Sync</BaseButton>
      </DynamicForm>
    </div>

    <BaseModal
      :open="popupConfirmDelete"
      @close="popupConfirmDelete = false"
      title="Archive this task?"
    >
      <p class="text-gray-600 mb-2">This will remove the task from the template. This can be restored in the archive</p>
      <div class="flex w-full justify-end space-x-2 pt-2">
        <BaseButton
          class="flex-1"
          @click.prevent="popupConfirmDelete = false"
        >
          cancel
        </BaseButton>
        <BaseButton
          class="flex-1"
          type="danger"
          @click.prevent="removeTask()"
        >
          archive
        </BaseButton>
      </div>
    </BaseModal>

    <BaseModal
      :open="popupConfirmSync"
      @close="popupConfirmSync = false"
      title="Sync tasks?"
    >
      <p class="text-gray-600 mb-2">Syncing tasks will make sure that every project using this template has this task.</p>
      <div class="flex w-full justify-end space-x-2 pt-2">
        <BaseButton
          type="danger"
          class="flex-1"
          @click.prevent="popupConfirmSync = false"
        >
          cancel
        </BaseButton>
        <BaseButton
          class="flex-1"
          @click.prevent="syncTask()"
        >
          sync
        </BaseButton>
      </div>
    </BaseModal>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DynamicForm from '@/components/DynamicForm.vue';

export default {
  name: 'TemplatesEditTasks',
  data: () => ({
    illegalFields: ['deadline', 'blockedBy', 'project', 'notes', 'state'],
    popupConfirmDelete: false,
    popupConfirmSync: false,
  }),
  components: {
    DynamicForm,
  },
  computed: {
    ...mapGetters({
      fields: 'tasks/fields',
      template: 'templates/single',
    }),
    task: {
      get() {
        return this.$store.state.tasks.single;
      },
      set(value) {
        this.$store.commit('tasks/setSingle', value);
      },
    },
    allowedFields() {
      let { fields } = this.$store.state.templates.single;
      if (!fields) fields = [];
      return fields.filter(
        (field) => (field.type || field.dataType) !== 'image',
      );
    },
  },
  methods: {
    ...mapActions({
      put_task: 'tasks/put',
      get_task: 'tasks/get_single',
      sync_task: 'tasks/sync',
      get_template: 'templates/get_single',
      delete_task: 'templates/delete_task',
    }),
    async save() {
      await this.put_task(this.task);
      // this.$router.push(`/templates/${this.$route.params.templateId}/tasks`);
    },
    copy(string) {
      navigator.clipboard.writeText(`\${${string}}`);
    },
    async syncTask() {
      await this.sync_task(this.task);
      this.popupConfirmSync = false;
    },
    async removeTask() {
      await this.delete_task(this.task._id);
      this.$router.push(`/templates/${this.$route.params.templateId}/tasks`);
    },
  },
  async mounted() {
    await this.get_task(this.$route.params.taskId);
    await this.get_template(this.$route.params.templateId);
  },
};
</script>
