<template>
  <button
    :class="{
      'px-2.5 py-1.5 text-xs': size === 'xs',
      'px-3 py-2 text-sm leading-4': size === 'sm',
      'px-4 py-2 text-sm': size === 'm',
      'px-4 py-2 text-base': size === 'lg',
      'px-6 py-3 text-base': size === 'xl',
      'text-white bg-primary hover:bg-opacity-70': type === 'primary',
      'text-white bg-danger hover:bg-opacity-70': type === 'danger',
      'text-indigo-700 bg-secondary hover:bg-opacity-70': type === 'secondary',
      'opacity-70 ': disabled
    }"
    :disabled="disabled"
    class="inline-flex items-center justify-center border border-transparent font-medium rounded shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'm',
      validator: (value) => ['xs', 'sm', 'm', 'lg', 'xl'].indexOf(value) !== -1,
    },
    type: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'secondary', 'danger'].indexOf(value) !== -1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
