<template>
  <div>
    <div class="sm:rounded-md relative -top-1">
      <div class="grid grid-cols-4 gap-2 m-4">
        <div
          v-for="col in ['blocked', 'todo', 'doing', 'done']"
          :key="col"
          class="bg-background-dark rounded shadow px-2 py-2"
          @dragover="dragover(col)"
        >
          <!-- COL TITLE -->
          <div class="text-lg font-bold text-gray-700 px-2 pb-2">{{col}}</div>
          <!-- TASKS -->
          <div class="flex flex-col gap-2">
            <!-- TASK -->
            <div
              v-for="task in cols[col]"
              :key="task._id"
              class="bg-background flex flex-col rounded p-2 cursor-pointer border-l-8"
              :class="{
                'border-blue-500': task.priority == 'low',
                'border-green-500': task.priority == 'normal',
                'border-orange-500': task.priority == 'high',
                'border-red-500': task.priority == 'critical',
                'opacity-100': dragging == task._id,
              }"
              :draggable="true"
              :id="task._id"
              @dragstart="dragstart"
              @dragend="dragend"
            >
              <h2>{{task.name}}</h2>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TasksList',
  computed: {
    ...mapGetters({
      activeTask: 'tasks/active',
    }),
    tasks: {
      get() {
        return this.$store.state.tasks.all;
      },
      set(value) {
        this.$store.commit('tasks/setAll', value);
      },
    },
    cols() {
      const obj = {
        blocked: [],
        todo: [],
        doing: [],
        done: [],
      };
      this.tasks.forEach((task) => {
        if (!obj[task.state]) return;
        obj[task.state].push(task);
      });
      return obj;
    },
  },
  data: () => ({
    dragging: {},
    dragtarget: '',
  }),
  methods: {
    ...mapActions({
      start_task: 'tasks/start',
      end_task: 'tasks/end',
      put_task: 'tasks/put',
    }),
    getTaskById(id) {
      const idx = this.tasks.findIndex((task) => task._id === id);
      console.log(idx);
      return this.tasks[idx];
    },
    dragstart(ev) {
      const task = this.getTaskById(ev.target.id);
      if (task.state === 'blocked') return;
      this.dragging = task;
      setTimeout(() => {
        ev.target.classList.add('hidden');
      }, 0);
    },
    async dragend() {
      if (this.dragging.state === 'doing') {
        await this.start_task(this.dragging);
      } else {
        await this.put_task(this.dragging);
      }
      this.dragging = {};
    },
    dragover(target) {
      if (target === this.dragtarget || target === 'blocked') return;
      this.dragtarget = target;
      this.dragging.state = target;
    },
  },
  async mounted() {
    if (!this.user) await this.me();
    // restrict access
    this.checkUserRoles('user');
  },
};
</script>
