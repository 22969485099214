<template>
  <div class="">
    <PageHeading title="Offerte editor">
      <div class="hidden sm:flex space-x-2">
        <BaseButton
          type="danger"
          @click="archive()"
        >
          Archive
        </BaseButton>
        <BaseButton @click="save()">
          Save
        </BaseButton>
        <BaseButton
          @click="download()"
          :disabled="generating"
          :class="{'bg-opacity-50': generating}"
        >
          Download
        </BaseButton>
      </div>
    </PageHeading>

    <!-- DESKTOP -->
    <section
      id="offerte"
      class="sm:px-16 pt-8 sm:pt-12 mx-auto py-8 print:px-8 hidden sm:block"
    >
      <!-- LOGO -->
      <div class="hidden sm:flex justify-end mb-8">
        <div class="flex flex-col justify-start">
          <p class="text-2xl">Daan Gysels</p>
          <h1 class=" font-bold text-5xl">Maatwerk</h1>
        </div>
      </div>

      <div class="flex justify-center sm:justify-between text-sm">
        <!--RECIPIENT-->
        <div class="flex flex-col justify-between border-2 shadow-xl p-4 rounded-xl sm:border-none sm:shadow-none sm:rounded-none">
          <div class="flex space-x-2 sm:text-xl">
            <div class="hidden sm:block">To:</div>
            <div class="flex flex-col">
              <select
                class="print-hidden font-bold bg-background-light outline-none border-0 p-0 sm:text-2xl cursor-pointer focus:outline-none focus:ring-0"
                :class="{'text-blue-500': !offerte.contact}"
                v-model="offerte.contact"
                ref="select_contact"
              >
                <option
                  :value="undefined"
                  disabled
                >Kies een ontvanger</option>
                <option
                  v-for="contact in contacts"
                  :key="contact._id"
                  :value="contact._id"
                  class="text-lg"
                >{{(contact.name.replace(/ /g, '').length > 0 && contact.name) || formatAddress(contact.address)}}</option>
              </select>
              <p class="print-visible hidden font-bold text-2xl pl-1">{{contact.name}}</p>
              <p
                v-if="contact && contact.address"
                class="pl-1 text-md "
              >{{formatAddress(contact.address)}}</p>
            </div>
          </div>
          <b class="hidden sm:block text-4xl">Offerte</b>
        </div>
        <!--SENDER-->
        <div class="hidden sm:flex flex-col whitespace-nowrap pr-4">
          <div class="flex space-x-2 pr-4 w-full">
            <div class="w-1/4 text-right">Van:</div>
            <div class="flex flex-col">
              <b>Daan Gysels Maatwerk</b>
              <p>Laarstraat 24</p>
              <p>2520 Ranst</p>
            </div>
          </div>
          <div class="mt-3 flex w-full">
            <p class="w-1/4 text-right pr-2">BTW:</p>
            <p>BE0749414179</p>
          </div>

          <div class="mt-3 flex w-full">
            <p class="w-1/4 text-right pr-2">Bank:</p>
            <p>KBC</p>
          </div>
          <div class="flex w-full">
            <p class="w-1/4 text-right pr-2">IBAN:</p>
            <p>BE44 7310 4988 4445</p>
          </div>

          <div class="mt-3 flex w-full">
            <p class="w-1/4 text-right pr-2">Tel:</p>
            <p>0483365570</p>
          </div>
          <div class="flex w-full">
            <p class="w-1/4 text-right pr-2">Email:</p>
            <p>daangysels@hotmail.be</p>
          </div>
        </div>
      </div>

      <!-- INTRO -->
      <div class="hidden sm:block mt-8 pl-4">
        <p class="text-sm">Geachte</p>
        <p class="mt-2 text-sm">Hierbij ontvangt u een vrijblijvende prijsopgave voor het leveren van onderstaande producten en diensten.</p>
      </div>

      <!-- BOXES -->
      <div class="flex flex-col sm:flex-row mt-4">
        <div class="flex-1 bg-blue-300 border-t-4 sm:border-t-0 sm:border-l-4 border-blue-600 flex sm:flex-col justify-between px-4 py-2">
          <p>Offertenummer</p>
          <p><b>{{new Date(offerte.period.start).getFullYear()}}-{{('0000'+offerte.factuurnr).split('').slice(-4).join('')}}</b></p>
        </div>
        <input
          @change="updateEndDate"
          v-model="offerte.period.start"
          id="startDate"
          type="date"
          class="w-0 h-0"
        >
        <label
          for="startDate"
          class="cursor-pointer flex-1 bg-blue-300 border-t-4 sm:border-t-0 sm:border-l-4 border-blue-600 flex sm:flex-col justify-between px-4 py-2"
        >
          <p>Offertedatum</p>
          <b>{{formatDate(offerte.period.start)}}</b>
        </label>
        <input
          @change="save"
          v-model="offerte.period.end"
          id="endDate"
          type="date"
          class="w-0 h-0"
        >
        <label
          for="endDate"
          class="cursor-pointer flex-1 bg-blue-300 border-t-4 border-b-4 sm:border-t-0 sm:border-b-0 sm:border-l-4 sm:border-r-4 border-blue-600 flex sm:flex-col justify-between px-4 py-2"
        >
          <p>Vervaldatum</p>
          <b>{{formatDate(offerte.period.end)}}</b>
        </label>
      </div>

      <!-- DELIVERABLES  -->
      <!-- DESKTOP DELIVERABLES -->
      <table class="hidden sm:table w-full mt-4 text-right">
        <thead class="border-t-2 border-b-2 border-blue-600 h-10">
          <tr class="uppercase">
            <th class="pl-16 text-left">Omschrijving</th>
            <th>Aantal</th>
            <th>Prijs</th>
            <th>BTW</th>
            <th class="pr-6">Totaal</th>
          </tr>
        </thead>
        <tbody class="border-b-2 border-blue-600">
          <tr
            @change="addDeliverable()"
            class="h-10 print-hidden"
          >
            <td class="pl-16 text-left">
              <input
                placeholder="Nieuw item"
                type="text"
                v-model="newItem.desc"
                class="w-full h-full bg-background-light"
              >
            </td>
            <td>
              <input
                placeholder="1"
                type="number"
                v-model="newItem.quantity"
                min="0"
                class="w-24 h-full bg-background-light text-right pr-2"
              >
            </td>
            <td>
              <input
                placeholder="0"
                type="number"
                v-model="newItem.price"
                min="0"
                class="w-24 h-full bg-background-light text-right"
              >
            </td>
            <td>
              <input
                placeholder="0%"
                type="number"
                v-model="newItem.btw"
                min="0"
                class="w-24 h-full bg-background-light text-right"
              >
            </td>
            <td class="pr-6 text-gray-400">0</td>
          </tr>
          <tr
            @keyup="save"
            @change="save"
            v-for="item in (offerte.deliverables || []).sort((a, b) => a.order - b.order)"
            :key="item._id"
            class="deliverable h-10"
          >
            <td class="pl-16 text-left relative">
              <div class="flex items-center absolute left-4 ">
                <div
                  @click="removeDeliverable(item)"
                  class="print-hidden pr-1 cursor-pointer text-red-300 hover:text-red-600 print:hidden"
                >
                  x
                </div>
                <div
                  v-if="item.order > 0"
                  @click="moveDeliverableUp(item)"
                  class="print-hidden cursor-pointer text-gray-300 hover:text-gray-600 print:hidden"
                >
                  <ArrowSmUpIcon class="w-5 h-5" />
                </div>
                <div
                  v-else
                  class="w-5"
                ></div>
                <div
                  v-if="item.order + 1 < offerte.deliverables.length"
                  @click="moveDeliverableDown(item)"
                  class="print-hidden cursor-pointer text-gray-300 hover:text-gray-600 print:hidden"
                >
                  <ArrowSmDownIcon class="w-5 h-5" />
                </div>

              </div>
              <input
                type="text"
                v-model="item.desc"
                class="w-full h-full bg-background-light "
              >
            </td>
            <td>
              <input
                type="number"
                v-model="item.quantity"
                min="0"
                class="w-24 h-full bg-background-light text-right pr-2 print:bg-white"
              >
            </td>
            <td>
              <input
                type="number"
                v-model="item.price"
                min="0"
                class="w-24 h-full bg-background-light  text-right print:bg-white"
              >
            </td>
            <td class="btw-cell flex flex-nowrap pt-2 justify-end items-center">
              <input
                type="number"
                v-model="item.btw"
                min="0"
                class="w-24 h-full text-right bg-background-light print:bg-white"
              >
              <p>%</p>
            </td>
            <td class="pr-6">{{((item.quantity || 1) * (item.price || 0)).toFixed(2)}}</td>
          </tr>

        </tbody>
      </table>

      <!-- CALCULATIONS -->
      <table class="hidden sm:table no-break w-full text-right">
        <thead class="">
          <tr class="">
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr class="w-full h-10">
            <td class="text-left pl-16"><b>TOTAAL EXCL. BTW</b></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="pr-6">€{{calculations.total.excl.toFixed(2)}}</td>
          </tr>
          <tr class="bg-blue-300">
            <td class="text-left pl-16"></td>
            <td>BTW%</td>
            <td>OVER</td>
            <td></td>
            <td></td>
          </tr>
          <tr
            v-for="btw in Object.keys(calculations.btw)"
            :key="btw"
            class="bg-blue-300"
          >
            <td class="text-left pl-16"></td>
            <td>{{btw}}%</td>
            <td>€{{calculations.btw[btw].toFixed(2)}}</td>
            <td></td>
            <td></td>
          </tr>
          <tr class="h-10">
            <td class="text-left pl-16"><b>TOTAAL BTW</b></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="pr-6">€{{calculations.total.btw.toFixed(2)}}</td>
          </tr>
          <tr class="border-t-2 border-blue-600 h-10">
            <td class="text-left pl-16"> <b>TE BETALEN</b></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="pr-6">€{{calculations.total.incl.toFixed(2)}}</td>
          </tr>
        </tbody>
      </table>

      <div class="px-4 mt-4 hidden sm:flex flex-col space-y-3 text-sm">
        <p>Ik hoop u hiermee voldoende geÏnformeerd te hebben.<br>
          Gelieve de offerte ondertekend terug te sturen voor akkoord</p>
        <p>Met vriendelijke groet,</p>
        <p>Daan Gysels</p>
      </div>

    </section>

    <!-- MOBILE -->
    <section class="sm:hidden ">
      <!-- TITLE AND DESC -->
      <TitleDesc
        class="base-container"
        title="Edit offerte"
        desc="Here you can edit all information that belongs to this offerte"
      />

      <!--RECIPIENT-->
      <div class="base-container flex space-x-2 items-center">
        <span>To:</span>
        <BaseSelect
          class="flex-1"
          :options="contacts"
          label=""
          name="contact"
          optionKey="_id"
          optionLabel="name"
          v-model="offerte.contact"
        />
      </div>

      <!-- BOXES -->
      <div class="flex flex-col sm:flex-row">
        <div class="flex-1 bg-blue-300 border-t-4 sm:border-t-0 sm:border-l-4 border-blue-600 flex sm:flex-col justify-between px-4 py-2">
          <p>Offertenummer</p>
          <p><b>{{new Date(offerte.period.start).getFullYear()}}-{{('0000'+offerte.factuurnr).split('').slice(-4).join('')}}</b></p>
        </div>
        <input
          @change="updateEndDate"
          v-model="offerte.period.start"
          id="startDate"
          type="date"
          class="w-0 h-0"
        >
        <label
          for="startDate"
          class="cursor-pointer flex-1 bg-blue-300 border-t-4 sm:border-t-0 sm:border-l-4 border-blue-600 flex sm:flex-col justify-between px-4 py-2"
        >
          <p>Offertedatum</p>
          <b>{{formatDate(offerte.period.start)}}</b>
        </label>
        <input
          @change="save"
          v-model="offerte.period.end"
          id="endDate"
          type="date"
          class="w-0 h-0"
        >
        <label
          for="endDate"
          class="cursor-pointer flex-1 bg-blue-300 border-t-4 border-b-4 sm:border-t-0 sm:border-b-0 sm:border-l-4 sm:border-r-4 border-blue-600 flex sm:flex-col justify-between px-4 py-2"
        >
          <p>Vervaldatum</p>
          <b>{{formatDate(offerte.period.end)}}</b>
        </label>
      </div>

      <!-- DELIVERABLES  -->
      <div class="flex flex-col">
        <div class="overflow-x-auto">
          <table class="bg-white table w-full mt-4 text-right">
            <thead class="border-t-2 border-b-2 border-blue-600 h-10">
              <tr class="uppercase">
                <th class="pl-16 text-left">Omschrijving</th>
                <th>Aantal</th>
                <th>Prijs</th>
                <th class="text-center">BTW</th>
                <th class="pr-6 text-right">Totaal</th>
              </tr>
            </thead>
            <tbody class="border-b-2 border-blue-600">
              <tr
                @change="addDeliverable()"
                class="h-10 print-hidden"
              >
                <td class="pl-16 text-left">
                  <input
                    placeholder="Nieuw item"
                    type="text"
                    v-model="newItem.desc"
                    class="w-96 h-full "
                  >
                </td>
                <td>
                  <input
                    placeholder="1"
                    type="number"
                    v-model="newItem.quantity"
                    min="0"
                    class="w-24 h-full text-right pr-2"
                  >
                </td>
                <td>
                  <input
                    placeholder="0"
                    type="number"
                    v-model="newItem.price"
                    min="0"
                    class="w-24 h-full text-right"
                  >
                </td>
                <td>
                  <input
                    placeholder="0%"
                    type="number"
                    v-model="newItem.btw"
                    min="0"
                    class="w-24 h-full text-right"
                  >
                </td>
                <td class="pr-6 text-gray-400">0</td>
              </tr>
              <tr
                @keyup="save"
                @change="save"
                v-for="item in (offerte.deliverables || []).sort((a, b) => a.order - b.order)"
                :key="item._id"
                class="deliverable h-10"
              >
                <td class="pl-16 text-left relative">
                  <div class="flex items-center absolute left-4 ">
                    <div
                      @click="removeDeliverable(item)"
                      class="print-hidden pr-1 cursor-pointer text-red-300 hover:text-red-600 print:hidden"
                    >
                      x
                    </div>
                    <div
                      v-if="item.order > 0"
                      @click="moveDeliverableUp(item)"
                      class="print-hidden cursor-pointer text-gray-300 hover:text-gray-600 print:hidden"
                    >
                      <ArrowSmUpIcon class="w-5 h-5" />
                    </div>
                    <div
                      v-else
                      class="w-5"
                    ></div>
                    <div
                      v-if="item.order + 1 < offerte.deliverables.length"
                      @click="moveDeliverableDown(item)"
                      class="print-hidden cursor-pointer text-gray-300 hover:text-gray-600 print:hidden"
                    >
                      <ArrowSmDownIcon class="w-5 h-5" />
                    </div>

                  </div>
                  <input
                    type="text"
                    v-model="item.desc"
                    class="w-full h-full "
                  >
                </td>
                <td>
                  <input
                    type="number"
                    v-model="item.quantity"
                    min="0"
                    class="w-24 h-full  text-right pr-2 print:bg-white"
                  >
                </td>
                <td>
                  <input
                    type="number"
                    v-model="item.price"
                    min="0"
                    class="w-24 h-full  text-right print:bg-white"
                  >
                </td>
                <td class="btw-cell flex flex-nowrap pt-2 justify-end items-center">
                  <input
                    type="number"
                    v-model="item.btw"
                    min="0"
                    class="w-24 h-full text-right print:bg-white"
                  >
                  <p>%</p>
                </td>
                <td class="pr-6">{{((item.quantity || 1) * (item.price || 0)).toFixed(2)}}</td>
              </tr>

              <tr class="w-full border-t-2 border-blue-600 h-10">
                <td class="text-left pl-16"><b>TOTAAL EXCL. BTW</b></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="pr-6">€{{calculations.total.excl.toFixed(2)}}</td>
              </tr>
              <tr class="bg-blue-300">
                <td class="text-left pl-16"></td>
                <td>BTW%</td>
                <td>OVER</td>
                <td></td>
                <td></td>
              </tr>
              <tr
                v-for="btw in Object.keys(calculations.btw)"
                :key="btw"
                class="bg-blue-300"
              >
                <td class="text-right "></td>
                <td>{{btw}}%</td>
                <td>€{{calculations.btw[btw].toFixed(2)}}</td>
                <td></td>
                <td></td>
              </tr>
              <tr class="h-10">
                <td class="text-left pl-16"><b>TOTAAL BTW</b></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="pr-6">€{{calculations.total.btw.toFixed(2)}}</td>
              </tr>
              <tr class="border-t-2 border-blue-600 h-10">
                <td class="text-left pl-16"> <b>TE BETALEN</b></td>
                <td></td>
                <td></td>
                <td></td>
                <td class="pr-6">€{{calculations.total.incl.toFixed(2)}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- ACTIONS -->
      <div class="flex justify-end space-x-2 mb-4 base-container">
        <BaseButton
          class="flex-1"
          type="danger"
          @click="archive()"
        >
          Archive
        </BaseButton>
        <BaseButton
          class="flex-1"
          @click="save()"
        >
          Save
        </BaseButton>
        <BaseButton
          @click="download()"
          :disabled="generating"
          class="flex-1"
          :class="{'bg-opacity-50': generating}"
        >
          Download
        </BaseButton>
      </div>
    </section>

    <ModalConfirm ref="confirmation" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ArrowSmUpIcon, ArrowSmDownIcon } from '@heroicons/vue/outline';
import PageHeading from '@/components/PageHeading.vue';
import TitleDesc from '@/components/TitleDesc.vue';
import ModalConfirm from '@/components/ModalConfirm.vue';

export default {
  name: 'offerteEditor',
  data: () => ({
    generating: false,
    inital: true,
    newItem: {
      desc: '',
    },
    debounceTimer: false,
    debounceInterval: 1000,
  }),
  components: {
    PageHeading,
    TitleDesc,
    ModalConfirm,
    ArrowSmUpIcon,
    ArrowSmDownIcon,
  },
  watch: {
    offerte: {
      deep: true,
      handler() {
        if (this.inital) {
          this.inital = false;
          return;
        }
        return this.save();
      },
    },
  },
  computed: {
    ...mapGetters({
      users: 'users',
      contacts: 'contacts/all',
      contact: 'contacts/single',
      fields: 'offertes/fields',
    }),
    offerte: {
      get() {
        const offerte = this.$store.state.offertes.single;
        if (!offerte.period) {
          const start = new Date();
          const end = new Date(start).setMonth(start.getMonth() + 1);
          offerte.period = { start, end };
        }
        return offerte;
      },
      async set(value) {
        this.$store.commit('offertes/setSingle', value);
      },
    },
    calculations() {
      const results = {
        btw: {},
        total: {
          excl: 0,
          incl: 0,
          btw: 0,
        },
      };
      (this.offerte.deliverables || []).forEach((el) => {
        results.total.excl += el.quantity * el.price;
        if (!results.btw[el.btw]) results.btw[el.btw] = 0;
        results.btw[el.btw] += el.quantity * el.price;
      });
      Object.keys(results.btw).forEach((btw) => {
        results.total.btw += (results.btw[btw] / 100) * btw;
      });
      results.total.incl = results.total.btw + results.total.excl;
      return results;
    },
  },
  methods: {
    ...mapActions({
      me: 'me',
      get_offerte: 'offertes/get_single',
      put_offerte: 'offertes/put',
      delete_offerte: 'offertes/delete',
      generate_pdf: 'offertes/put_pdf',
      get_contacts: 'contacts/get_all',
      get_contact: 'contacts/get_single',
    }),
    async save() {
      if (this.debounceTimer) clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(async () => {
        if (this.offerte.contact) await this.get_contact(this.offerte.contact);
        this.offerte.total = this.calculations.total.incl;
        await this.put_offerte(this.offerte);
      }, this.debounceInterval);
    },
    async archive() {
      const archive = await this.$refs.confirmation.ask(
        'Archive offerte?',
        'The offerte will still be available in the archive and count towards invoice numbering.',
      );
      if (archive) {
        await this.delete_offerte(this.offerte);
        this.$router.push('/offertes');
      }
    },

    // add a deliverable
    async addDeliverable() {
      this.offerte.deliverables.push({
        ...this.newItem,
        quantity: 1,
        price: 0,
        btw: 0,
        order: this.offerte.deliverables.length,
      });
      this.newItem = {
        desc: '',
      };
    },
    moveDeliverableDown(item) {
      if (item.order >= this.offerte.deliverables.length) return;
      const newOrder = item.order + 1;
      const index = this.offerte.deliverables.findIndex(
        (el) => el.order === newOrder,
      );
      this.offerte.deliverables[index].order = item.order;
      item.order = newOrder;
    },
    moveDeliverableUp(item) {
      if (item.order === 0) return;
      const newOrder = item.order - 1;
      const index = this.offerte.deliverables.findIndex(
        (el) => el.order === newOrder,
      );
      this.offerte.deliverables[index].order = item.order;
      item.order = newOrder;
    },
    async removeDeliverable(item) {
      this.offerte.deliverables = this.offerte.deliverables.filter(
        (el) => el._id.toString() !== item._id.toString(),
      );
    },

    // nicely format an address
    formatAddress(address) {
      if (!address || !address.street) return '----';
      let str = `${address.street}`;
      str += `${address.house_number ? ' ' : ''}${address.house_number || ''}`;
      str += `${address.zip || address.city ? ', ' : ''}`;
      str += `${address.zip || ''} ${address.city || ''}`;
      return str;
    },
    // nicely format a date
    formatDate(d) {
      const date = new Date(d);
      if (date === 'Invalid Date') return 'set date';
      return `${`0${(date.getMonth() + 1).toString()}`.slice(-2)}-${`0${date
        .getDate()
        .toString()}`.slice(-2)}-${date.getFullYear()}`;
    },
    // set the end date one month after the start
    async updateEndDate() {
      const start = new Date(this.offerte.period.start);
      this.offerte.period.end = new Date(start).setMonth(start.getMonth() + 1);
      // await this.save();
    },

    // is the offerte valid
    validOfferte() {
      return (
        this.offerte.contact
        && this.offerte.deliverables
        && this.offerte.deliverables.length > 0
      );
    },

    // download the offerte
    async download() {
      if (!this.validOfferte()) return alert('Oops, this is not a valid offerte!');
      this.generating = true;
      const url = await this.generate_pdf(this.offerte);
      if (url) window.open(url);
      this.generating = false;
      // window.print();
    },
  },
  async mounted() {
    if (!this.user) await this.me();
    // restrict access
    this.checkUserRoles('user');
    await this.get_contacts();
    await this.get_offerte(this.$route.params.offerteId);
    if (this.offerte.contact) await this.get_contact(this.offerte.contact);
  },
};
</script>

<style scoped>
#offerte {
  max-width: 29.7cm;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="date"] {
  width: 0;
  padding: 0;
}

input {
  @apply py-0 border-0 outline-none focus:outline-none focus:ring-0;
}

@media print {
  .bg-background-light {
    opacity: 0;
    background-color: rgba(241, 0, 0, 1) !important;
  }
  #header,
  #pageHeading,
  #sidebar {
    background-color: white !important;
    display: none !important;
  }
  .print-hidden {
    display: none !important;
  }
  .print-visible {
    display: unset !important;
  }

  .deliverable {
    height: 4px;
    padding: 0;
  }
  .btw-cell {
    padding-top: 0;
  }

  .no-break {
    page-break-before: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-after: auto; /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-inside: avoid; /* or 'auto' */
  }

  .bg-background-light {
    background-color: white;
  }

  body,
  #offerte,
  html {
    background-color: white;
  }
  #offerte {
    @apply shadow-none rounded-none;
    page-break-after: always;
    min-height: 100vh;
    z-index: 100;
    position: absolute;
    top: -32px;
  }
}
</style>
