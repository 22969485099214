<template>
  <div class="">
    <PageHeading title="Contacts">
      <BaseButton @click="$router.push('/contacts/create')">New contact</BaseButton>
    </PageHeading>
    <div class="sm:rounded-md">
      <ul
        role="
      list"
        class="divide-y divide-gray-200"
      >
        <li
          class="bg-background"
          v-for="(contact, index) in contacts"
          :key="index"
        >
          <router-link
            :to="`/contacts/${contact._id}`"
            class="block hover:bg-background-light"
          >
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 flex items-center">
                <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                  <div>
                    <p class="text-sm font-medium text-primary truncate">
                      <span v-if="contact.name">{{ contact.name }}</span>
                      <span
                        v-else
                        class="text-red-400"
                      >No name</span>
                    </p>
                    <p class="mt-2 flex items-center text-sm text-gray-500">
                      <HomeIcon
                        class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span
                        v-if="contact.address && contact.address.street"
                        class="truncate"
                      >{{ formatAddress(contact.address) }}</span>
                      <span
                        v-else
                        class="truncate text-red-400"
                      >
                        No address
                      </span>

                    </p>
                  </div>

                  <div class="hidden md:block">
                    <div>
                      <p
                        v-if="contact.email"
                        class="mt-2 flex items-center text-sm text-gray-500"
                      >
                        <MailIcon
                          class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span class="truncate">{{ contact.email || '--------' }}</span>
                      </p>
                      <p
                        v-else
                        class="h-5"
                      ></p>

                      <p
                        v-if="contact.phone"
                        class="mt-2 flex items-center text-sm text-gray-500"
                      >
                        <PhoneIcon
                          class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        {{ contact.phone || '--------' }}
                      </p>
                      <p
                        v-else
                        class="h-5"
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <ChevronRightIcon
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  PhoneIcon,
  ChevronRightIcon,
  MailIcon,
  HomeIcon,
} from '@heroicons/vue/solid';
import PageHeading from '@/components/PageHeading.vue';

export default {
  name: 'home',
  components: {
    PageHeading,
    PhoneIcon,
    ChevronRightIcon,
    MailIcon,
    HomeIcon,
  },
  computed: mapGetters({
    users: 'users',
    contacts: 'contacts/all',
  }),
  methods: {
    ...mapActions({
      me: 'me',
      getAll: 'contacts/get_all',
    }),
    formatAddress(address) {
      return `${address.city || ''}${address.city ? ', ' : ''}${
        address.street
      } ${address.house_number || ''}`;
    },
  },
  async mounted() {
    if (!this.user) await this.me();
    console.log('mount home');
    // restrict access
    this.checkUserRoles('user');
    await this.getAll();
  },
};
</script>
