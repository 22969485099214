<template>
  <div class="bg-background-light ">
    <div class="">

      <TitleDesc
        class="base-container"
        title="Add fragments"
        desc="Here you can add fragments to your project. <br> A fragment is a self contained part of the project that can be reused as a template in another project."
      />

      <div class="space-y-2 mb-6 base-container">
        <p class="text-gray-600">You can add a blank fragment, or select one of the templates.</p>
        <div class="flex space-x-2 items-end">
          <BaseSelect
            class="w-full"
            v-model="templateId"
            label=""
            name="fragment"
            optionKey="_id"
            optionLabel="name"
            :options="templates"
          ></BaseSelect>

          <BaseButton
            class="flex-grow-0"
            @click="createFragment()"
          >Add</BaseButton>
        </div>
      </div>

      <div class="space-y-2"></div>

    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import TitleDesc from '@/components/TitleDesc.vue';

export default {
  name: 'ProjectsEditFragmentsAdd',
  data: () => ({
    templateId: '',
  }),
  components: {
    TitleDesc,
  },
  computed: {
    templates() {
      return [
        {
          _id: '',
          name: 'Blank fragment',
        },
      ].concat(
        this.$store.state.templates.all.filter((el) => el.name !== 'base'),
      );
    },
  },
  methods: {
    ...mapActions({
      add_fragment: 'projects/post_fragment',
      get_project: 'projects/get_single',
      get_templates: 'templates/get_all',
    }),
    async createFragment() {
      await this.add_fragment(this.templateId);
    },
  },
  async mounted() {
    await this.get_templates();
    await this.get_project(this.$route.params.projectId);
  },
};
</script>
