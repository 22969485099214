import { createRouter, createWebHashHistory } from 'vue-router';

import Home from '@/pages/Home.vue';
import Todo from '@/pages/Todo.vue';

import Error from '@/pages/Error.vue';

import Image from '@/pages/Image.vue';

import Login from '@/pages/auth/Login.vue';
import MagicLink from '@/pages/auth/MagicLink.vue';
import ForgotPassword from '@/pages/auth/ForgotPassword.vue';
import ResetPassword from '@/pages/auth/ResetPassword.vue';

import Tasks from '@/pages/tasks/Tasks.vue';
import TasksCreate from '@/pages/tasks/TasksCreate.vue';
import TasksList from '@/pages/tasks/TasksList.vue';
import TasksTable from '@/pages/tasks/TasksTable.vue';
import TasksKanban from '@/pages/tasks/TasksKanban.vue';
import TasksEdit from '@/pages/tasks/TasksEdit.vue';
import TasksEditGeneral from '@/pages/tasks/TasksEditGeneral.vue';
import TasksEditImages from '@/pages/tasks/TasksEditImages.vue';

import Contacts from '@/pages/contacts/Contacts.vue';
import ContactsCreate from '@/pages/contacts/ContactsCreate.vue';
import ContactsEdit from '@/pages/contacts/ContactsEdit.vue';

import Offertes from '@/pages/offertes/Offertes.vue';
import OffertesEditor from '@/pages/offertes/OffertesEditor.vue';

import Projects from '@/pages/projects/Projects.vue';
import ProjectsCreate from '@/pages/projects/ProjectsCreate.vue';
import ProjectsEdit from '@/pages/projects/ProjectsEdit.vue';
import ProjectsEditGeneral from '@/pages/projects/ProjectsEditGeneral.vue';
import ProjectsEditImages from '@/pages/projects/ProjectsEditImages.vue';
import ProjectsEditFragmentsAdd from '@/pages/projects/ProjectsEditFragmentsAdd.vue';
import ProjectsEditFragments from '@/pages/projects/ProjectsEditFragments.vue';

import Templates from '@/pages/templates/Templates.vue';
// import TemplatesCreate from '@/pages/templates/TemplatesCreate.vue';
import TemplatesEdit from '@/pages/templates/TemplatesEdit.vue';
import TemplatesEditGeneral from '@/pages/templates/TemplatesEditGeneral.vue';
import TemplatesEditFields from '@/pages/templates/TemplatesEditFields.vue';
import TemplatesEditTasks from '@/pages/templates/TemplatesEditTasks.vue';
import TemplatesEditTasksSelect from '@/pages/templates/TemplatesEditTasksSelect.vue';
import TemplatesEditTask from '@/pages/templates/TemplatesEditTask.vue';

const routes = [
  { path: '/', name: 'Home', component: Home },

  { path: '/settings', name: 'Settings', component: Todo },
  { path: '/timings', name: 'Timings', component: Todo },
  { path: '/profile', name: 'Profile', component: Todo },

  { path: '/error/:code', name: 'Error', component: Error },

  { path: '/images/:imageId', name: 'Image', component: Image },

  { path: '/auth/login', name: 'Login', component: Login },
  { path: '/auth/magic-link/:token', name: 'MagicLink', component: MagicLink },
  { path: '/auth/reset-password', name: 'ForgotPassword', component: ForgotPassword },
  { path: '/auth/reset-password/:id', name: 'ResetPassword', component: ResetPassword },

  /* TASKS */
  { path: '/tasks', redirect: '/tasks/list' },
  { path: '/tasks/create', name: 'TasksCreate', component: TasksCreate },
  {
    path: '/tasks',
    name: 'Tasks',
    component: Tasks,
    children: [
      { path: 'list', name: 'TasksList', component: TasksList },
      { path: 'table', name: 'TasksTable', component: TasksTable },
      { path: 'kanban', name: 'TasksKanban', component: TasksKanban },
      { path: 'calendar', name: 'TasksCalendar', component: Todo },
    ],
  },
  {
    path: '/tasks/:taskId',
    name: 'TasksEdit',
    component: TasksEdit,
    children: [
      { path: 'general', name: 'TasksEditGeneral', component: TasksEditGeneral },
      { path: 'images', name: 'TasksEditImages', component: TasksEditImages },
    ],
  },
  // { path: '/tasks/:taskId', name: 'TasksEdit', component: TasksEdit },

  /* CONTACTS */
  { path: '/contacts', name: 'Contacts', component: Contacts },
  { path: '/contacts/create', name: 'ContactsCreate', component: ContactsCreate },
  { path: '/contacts/:contactId', name: 'ContactsEdit', component: ContactsEdit },

  /* OFFERTES */
  { path: '/offertes', name: 'Offertes', component: Offertes },
  // { path: '/offertes/create', name: 'OffertesCreate', component: OffertesCreate },
  { path: '/offertes/:offerteId', name: 'OffertesEditor', component: OffertesEditor },

  /* PROJECTS */
  { path: '/projects', name: 'Projects', component: Projects },
  { path: '/projects/create', name: 'ProjectsCreate', component: ProjectsCreate },
  {
    path: '/projects/:projectId',
    name: 'ProjectsEdit',
    component: ProjectsEdit,
    children: [
      { path: 'general', name: 'ProjectsEditGeneral', component: ProjectsEditGeneral },
      { path: 'images', name: 'ProjectsEditImages', component: ProjectsEditImages },
      { path: 'fragments/add', name: 'ProjectsEditFragmentsAdd', component: ProjectsEditFragmentsAdd },
      { path: 'fragments/:fragmentId', name: 'ProjectsEditFragments', component: ProjectsEditFragments },
    ],
  },

  /* TEMPLATES */
  { path: '/templates', name: 'Templates', component: Templates },
  // { path: '/templates/create', name: 'TemplatesCreate', component: TemplatesCreate },
  {
    path: '/templates/:templateId',
    name: 'TemplatesEdit',
    component: TemplatesEdit,
    children: [
      { path: 'general', name: 'TemplatesEditGeneral', component: TemplatesEditGeneral },
      { path: 'fields', name: 'TemplatesEditFields', component: TemplatesEditFields },
      {
        path: 'tasks',
        name: 'TemplatesEditTasksSelect',
        component: TemplatesEditTasks,
        children: [
          { path: '', name: 'TemplatesEditTasksSelect', component: TemplatesEditTasksSelect },
          { path: ':taskId', name: 'TemplatesEditTask', component: TemplatesEditTask },
        ],
      },
    ],
  },

];

const router = createRouter({
  mode: 'history',
  history: createWebHashHistory(),
  routes,
});

export default router;
