<template>
  <div class="">
    <PageHeading title="Coming soon!"></PageHeading>
    <div class="w-full text-center pt-64">
      <h1 class="text-4xl font-bold ">Not implemented yet...</h1>
      <h2 class="text-3xl">Check back soon!</h2>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageHeading from '@/components/PageHeading.vue';

export default {
  name: 'home',
  components: {
    PageHeading,
  },
  computed: mapGetters(['user']),
  methods: mapActions(['me']),
  async mounted() {
    if (!this.user) await this.me();
    console.log('mount home');
    // restrict access
    this.checkUserRoles('user');
  },
};
</script>
