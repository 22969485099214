<template>

  <div class="bg-background-light ">
    <PageHeading title="Create a project">
    </PageHeading>

    <TitleDesc
      class="base-container"
      title="Basic settings"
      desc="Here you can edit the basic settings of your new project. You can still change these later."
    />

    <section class="base-container base-card">
      <DynamicForm
        :fields="fields.filter(f => f.key != 'done')"
        v-model="project"
        @submit="submit()"
      />
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageHeading from '@/components/PageHeading.vue';
import DynamicForm from '@/components/DynamicForm.vue';
import TitleDesc from '@/components/TitleDesc.vue';

export default {
  name: 'home',
  data: () => ({
    project: {
      name: '',
      desc: '',
      client: '',
      fields: [],
      start: new Date(),
      deadline: new Date(),
      budget: 0,
    },
  }),
  components: {
    PageHeading,
    TitleDesc,
    DynamicForm,
  },
  computed: mapGetters({
    users: 'users',
    projects: 'projects/all',
    contacts: 'contacts/all',
    fields: 'projects/fields',
  }),
  methods: {
    ...mapActions({
      me: 'me',
      post_project: 'projects/post',
      get_contacts: 'contacts/get_all',
    }),
    async submit() {
      const newProject = await this.post_project(this.project);
      this.$router.push(`/projects/${newProject._id}/general`);
    },
    async setContacts() {
      await this.get_contacts();
      const contactsIndex = this.fields.findIndex((el) => el.key === 'client');
      this.fields[contactsIndex].options = this.contacts;
    },
    defaultDeadline() {
      const today = new Date();
      const result = new Date();
      const nextMonth = today.getMonth() + 1;
      if (nextMonth > 12) {
        result.setFullYear(today.getFullYear() + 1);
        result.setMonth(0);
      } else {
        result.setMonth(nextMonth);
      }
      return result;
    },
  },
  async mounted() {
    if (!this.user) await this.me();
    // restrict access
    this.checkUserRoles('user');
    await this.setContacts();
    this.project.deadline = this.defaultDeadline();
  },
};
</script>
