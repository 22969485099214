<template>
  <div class="bg-background-light">

    <PageHeading title="Edit task"></PageHeading>

    <!-- Tabs -->
    <div
      id="tabs"
      class="z-10 fixed top-16 bg-background-dark"
    >
      <div class="block">
        <div class="border-b border-gray-200">
          <nav
            class="-mb-px flex"
            aria-label="Tabs"
          >
            <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
            <router-link
              :to="`/tasks/${$route.params.taskId}/general`"
              class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-full py-4 px-1 text-center border-b-2 font-medium text-sm"
              :class="{ 'border-primary text-primary': /general/.test($route.path)}"
            >
              General
            </router-link>
            <router-link
              :to="`/tasks/${$route.params.taskId}/images`"
              class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 w-full py-4 px-1 text-center border-b-2 font-medium text-sm"
              :class="{ 'border-primary text-primary': /images/.test($route.path)}"
            >
              Images
            </router-link>
          </nav>
        </div>
      </div>
    </div>

    <router-view class="relative top-12"></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageHeading from '@/components/PageHeading.vue';

export default {
  name: 'home',
  data: () => ({}),
  components: {
    PageHeading,
  },
  computed: mapGetters(['user']),
  methods: {
    ...mapActions({
      me: 'me',
    }),
  },
  async mounted() {
    if (!this.user) await this.me();
    // restrict access
    this.checkUserRoles('user');
  },
};
</script>
