<template>
  <div>
    <TitleDesc
      class="base-container"
      title="Task info"
      desc="Here you can edit the info for this task."
    >
      <BaseButton
        type="danger"
        @click="archive()"
      >
        archive
      </BaseButton>
      <BaseButton
        class="row-start-1 sm:col-start-2"
        @click="put_task(this.task)"
      >
        save
      </BaseButton>

    </TitleDesc>

    <div class="base-container base-card">
      <div>
        <h2 class="text-xl font-medium">{{task.dynamicName}}</h2>
        <p class="">{{task.dynamicDesc}}</p>
      </div>
      <DynamicForm
        :fields="fields.filter(el => !['name', 'desc'].includes(el.key))"
        v-model="task"
        @submit="put_task(this.task)"
      >
        <BaseButton
          type="danger"
          @click.prevent="archive()"
        >
          archive
        </BaseButton>
      </DynamicForm>
    </div>

  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import TitleDesc from '@/components/TitleDesc.vue';
import DynamicForm from '@/components/DynamicForm.vue';

export default {
  name: 'general',
  components: {
    TitleDesc,
    DynamicForm,
  },
  computed: {
    ...mapGetters({
      fields: 'tasks/fields',
      projects: 'projects/all',
      tasks: 'tasks/all',
    }),
    task: {
      get() {
        return this.$store.state.tasks.single;
      },
      set(value) {
        this.$store.commit('tasks/setSingle', value);
      },
    },
  },
  methods: {
    ...mapActions({
      get_projects: 'projects/get_all',
      get_tasks: 'tasks/get_all',
      get_task: 'tasks/get_single',
      put_task: 'tasks/put',
      delete_task: 'tasks/delete',
    }),
    async archive() {
      // TODO ask confirmation
      await this.delete_task(this.task._id);
      this.$router.push('/tasks/list');
    },
    async setProjects() {
      await this.get_projects();
      const projectsIndex = this.fields.findIndex((el) => el.key === 'project');
      this.fields[projectsIndex].options = this.projects;
      this.fields[projectsIndex].validators = {};
    },
    async setTasks() {
      await this.get_tasks();
      const blockedIndex = this.fields.findIndex(
        (el) => el.key === 'blockedBy',
      );
      this.fields[blockedIndex].options = this.tasks;
    },
  },
  async mounted() {
    await this.get_task(this.$route.params.taskId);
    await this.setProjects();
    await this.setTasks();
  },
};
</script>
