<template>
  <div class="bg-background-light ">

    <TitleDesc
      class="base-container mb-2"
      title="Template fields"
      desc="Here you can configure the fields. <br>Fields are the dynamic part of the template, these can have diffrent values for each project."
    />

    <div class="">
      <!-- Add field -->
      <section
        v-if="template.fields && template.fields.length > 0"
        class="base-container base-card "
      >
        <div class="grid grid-cols-7 gap-2">
          <h2 class="text-xl mb-4 col-span-6">Edit Fields</h2>
          <div class="flex items-start">
            <BaseButton
              class="w-full"
              @click="create_field($route.params.templateId)"
            >
              <PlusIcon class="w-6 h-6 sm:w-5 sm:h-5" />
            </BaseButton>
          </div>
        </div>
        <div
          v-for="field of template.fields"
          :key="field._id"
          class="grid grid-cols-7 gap-2 w-full"
        >
          <!-- Type -->
          <DynamicField
            class="col-span-3"
            :field="fields[0]"
            v-model="field[fields[0].key]"
            @update:modelValue="save(field)"
          />
          <!-- Label and delete -->
          <DynamicField
            class="col-span-3 "
            :field="fields[1]"
            v-model="field[fields[1].key]"
            @change="save(field)"
          />
          <div class="flex items-end col-span-1">
            <BaseButton
              type="danger"
              class="w-full flex-shrink-0"
              @click="confirmDeleteField(field._id)"
            >
              <TrashIcon class="w-6 h-6 sm:w-5 sm:h-5" />
            </BaseButton>
          </div>
        </div>
      </section>

      <!-- No fields yet -->
      <div
        class="base-container"
        v-else
      >
        <EmptyState
          text="No fields have been added yet, create a field first."
          buttonText="Add field"
          @buttonClick="create_field($route.params.templateId)"
        />
      </div>
    </div>

    <BaseModal
      :open="popupConfirmDelete"
      @close="popupConfirmDelete = false"
      title="Archive this field?"
    >
      <p class="text-gray-600 mb-2">Archived fields can still be restored in the archive.</p>
      <div class="flex w-full justify-end space-x-2 pt-2">
        <BaseButton
          class="flex-1"
          @click.prevent="popupConfirmDelete = false"
        >
          cancel
        </BaseButton>
        <BaseButton
          class="flex-1"
          type="danger"
          @click.prevent="deleteField()"
        >
          archive
        </BaseButton>
      </div>
    </BaseModal>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { TrashIcon, PlusIcon } from '@heroicons/vue/outline';
import DynamicField from '@/components/DynamicField.vue';
import EmptyState from '@/components/EmptyState.vue';
import TitleDesc from '@/components/TitleDesc.vue';

export default {
  name: 'TemplatesEditFields',
  data: () => ({
    popupConfirmDelete: false,
    idToDelete: '',
  }),
  components: {
    DynamicField,
    PlusIcon,
    TrashIcon,
    EmptyState,
    TitleDesc,
  },
  computed: {
    ...mapGetters({
      fields: 'fields/fields',
    }),
    template: {
      get() {
        return this.$store.state.templates.single;
      },
      set(value) {
        return this.$store.commit('templates/setSingle', value);
      },
    },
  },
  methods: {
    ...mapActions({
      create_field: 'templates/post_field',
      put_field: 'fields/put',
      delete_field: 'templates/delete_field',
    }),
    updateKey(field) {
      field.key = field.label
        .toLowerCase()
        .replace(/^([0-9]+)/, '_$1')
        .replace(/ +/g, '_');
    },
    confirmDeleteField(fieldId) {
      this.idToDelete = fieldId;
      this.popupConfirmDelete = true;
    },
    async deleteField() {
      await this.delete_field(this.idToDelete);
      this.popupConfirmDelete = false;
    },
    async save(field) {
      this.updateKey(field);
      await this.put_field(field);
    },
  },
};
</script>
