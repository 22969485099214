import { mapActions, mapGetters } from 'vuex';

export default {
  // computed: {
  //   user() { return this.$store.state.auth.user; },
  // },
  computed: mapGetters(['user']),
  methods: {
    ...mapActions(['me']),
    async checkUserRoles(roles = false, redirect = true) {
      // unprotected
      if (!roles) return true;
      // check if theres a user
      if (!this.user) {
        if (redirect) return this.$router.push('/auth/login');
        return false;
      }
      // make sure roles is an array
      let checkRoles;
      if (!Array.isArray(roles)) checkRoles = [roles];
      else checkRoles = roles;
      // check the roles
      if (checkRoles.some(role => !this.user.roles.includes(role))) {
        if (redirect) return this.$router.push('/error/403');
        return false;
      }
      return true;
    },
  },
};
