<template>
  <div class="bg-background-light">
    <PageHeading title="Create a contact" />

    <TitleDesc
      class="base-container"
      title="New contact"
      desc="Here you can add the info for the new contact."
    >
      <BaseButton
        class="sm:col-start-2"
        @click="$refs.form.submit()"
      >
        save
      </BaseButton>
    </TitleDesc>

    <section class="base-container base-card">
      <DynamicForm
        ref="form"
        :fields="fields"
        v-model="contact"
        @submit="submit()"
      />
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageHeading from '@/components/PageHeading.vue';
import TitleDesc from '@/components/TitleDesc.vue';
import DynamicForm from '@/components/DynamicForm.vue';

export default {
  name: 'home',
  data: () => ({
    contact: {
      name: '',
      email: '',
      phone: '',
      address: {
        street: '',
        house_number: 0,
        appartement: '',
        city: '',
      },
    },
  }),
  components: {
    PageHeading,
    DynamicForm,
    TitleDesc,
  },
  computed: mapGetters({
    users: 'users',
    contacts: 'contacts/all',
    fields: 'contacts/fields',
  }),
  methods: {
    ...mapActions({
      me: 'me',
      post_contact: 'contacts/post',
    }),
    async submit() {
      console.log('submit');
      await this.post_contact(this.contact);
      this.$router.push('/contacts');
    },
  },
  async mounted() {
    if (!this.user) await this.me();
    // restrict access
    this.checkUserRoles('user');
  },
};
</script>
