const defaultDuration = 1000;

export default {
  state: {
    notifications: [
      { show: false },
      { show: false },
      { show: false },
      { show: false },
      { show: false },
    ],
  },
  getters: {
    notifications: (state) => state.notifications,
  },
  actions: {
    closeAllNotifications: ({ commit }) => commit('closeAllNotifications'),
    closeNotification: ({ commit }, index) => commit('closeNotification', index),
    pushNotification: ({ commit }, notification) => {
      notification.show = true;
      commit('pushNotification', notification);
    },
    // pushError({ commit }, notification) {
    //   notification.show = true;
    //   notification.type = 'error';
    //   commit('push', notification);
    // },
  },
  mutations: {
    closeAllNotifications: (state) => {
      for (let i = 0; i < state.notifications.length; i += 1) {
        state.notifications[i].show = false;
      }
    },
    closeNotification: (state, index) => { state.notifications[index].show = false; },
    pushNotification: (state, notification) => {
      const i = state.notifications.findIndex(el => !el.show);
      state.notifications[i] = notification;
      if (!notification.duration || notification.duration > 0) {
        notification.timeout = setTimeout(
          () => state.notifications[i].show = false,
          defaultDuration || notification.duration,
        );
      }
    },
  },
};
