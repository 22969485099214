<template>
  <div class="">

    <TitleDesc
      class="py-6 base-container"
      title="Task list"
      desc="This page gives an overview of all the tasks in list form. The table can be configured, and the tasks can be filtered."
    />

    <!-- BULK ACTIONS -->
    <section class="flex items-center space-x-2 base-container">
      <div>
        <BaseButton
          @click="archiveSelected()"
          type="danger"
          :class="{'bg-opacity-50': !selectedTasks || selectedTasks.length <= 0}"
          :disabled="!selectedTasks || selectedTasks.length <= 0"
        >
          <TrashIcon class="w-5 h-5" />
        </BaseButton>
      </div>

      <div class="text-gray-500">(<span>{{selectedTasks.length}}</span> selected)</div>
    </section>

    <DynamicTable
      class="md:mx-12"
      v-if="tasks && tasks.length > 0"
      :fields="fields"
      :data="tasks"
      :buttons="false"
      :config="true"
      :selectable="true"
      :class="{ 'hidden': !tableVisible }"
      @select="selectTasks($event)"
      @clickElement="$emit('open_task', $event)"
      @config="popupConfigureTable = true"
    />

    <div
      v-else
      class="base-container"
    >

      <EmptyState
        text="No tasks yet!"
        buttonText="New task"
        @buttonClick="$emit('create_task')"
      />
    </div>

    <BaseModal
      :open="popupConfigureTable"
      @close="popupConfigureTable = false"
      title="Configure the table"
    >
      <p class="text-gray-600 mb-2">Configure the table to your liking. <br>You can select the fields shown in the table</p>
      <BaseSelect
        class="w-full"
        :options="$store.state.tasks.fields"
        v-model="visibleFields"
        optionKey="key"
        optionLabel="label"
        :single="false"
        label="Visible fields"
        name="visibleFields"
      />

    </BaseModal>

    <ModalConfirm ref="confirmation" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { TrashIcon } from '@heroicons/vue/outline';
import DynamicTable from '@/components/DynamicTable.vue';
import TitleDesc from '@/components/TitleDesc.vue';
import ModalConfirm from '@/components/ModalConfirm.vue';
import EmptyState from '@/components/EmptyState.vue';

export default {
  name: 'TasksList',
  data: () => ({
    // hack to force the table to rerender
    tableVisible: true,
    popupConfigureTable: false,
    selectedTasks: [],
  }),
  components: {
    DynamicTable,
    EmptyState,
    TitleDesc,
    TrashIcon,
    ModalConfirm,
  },
  computed: {
    ...mapGetters({
      users: 'users',
      tasks: 'tasks/all',
      fields: 'tasks/fields',
      query: 'tasks/query',
    }),
    fields: {
      get() {
        return this.$store.state.tasks.fields.filter((field) => this.visibleFields.includes(field.key));
      },
    },
    visibleFields: {
      get() {
        return this.$store.state.tasks.visibleFields;
      },
      set(value) {
        // save to localstorage
        window.localStorage.setItem(
          'task_visibleFields',
          JSON.stringify(value),
        );
        this.$store.commit('tasks/setVisibleFields', value);
      },
    },
  },
  watch: {
    visibleFields() {
      // hack to force the table to rerender
      this.tableVisible = false;
      setTimeout(() => {
        this.tableVisible = true;
      }, 1);
    },
  },
  methods: {
    ...mapActions({
      me: 'me',
      delete_task: 'tasks/delete',
    }),
    selectTasks(selected) {
      this.selectedTasks = Object.keys(selected).filter((el) => selected[el]);
    },
    async archiveSelected() {
      const archive = await this.$refs.confirmation.ask(
        'Archive these tasks?',
        `You are about to archive ${this.selectedTasks.length} tasks, are you sure?`,
      );
      if (archive) {
        this.selectedTasks.forEach(async (id) => {
          await this.delete_task(id);
        });
      }
    },
  },
  async mounted() {
    if (!this.user) await this.me();
    // restrict access
    this.checkUserRoles('user');
    // load the visible fields setting from localstorage
    const visibleFields = window.localStorage.getItem('task_visibleFields');
    if (visibleFields) this.visibleFields = JSON.parse(visibleFields);
  },
};
</script>
