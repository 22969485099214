<template>
  <div>
    <div
      v-if="tasks && tasks.length > 0"
      class="sm:rounded-md relative -top-1"
    >

      <ul
        role="
      list"
        class="divide-y divide-gray-200"
      >
        <li
          class="bg-background "
          v-for="(task, index) in tasks"
          :key="index"
        >
          <!-- @click="$router.push(`/tasks/${task._id}/general`)" -->
          <div
            @click="$emit('open_task', task)"
            class="block hover:bg-background-light border-l-8 border-blue-500"
            :class="{
                'border-blue-500': task.priority == 'low',
                'border-green-500': task.priority == 'normal',
                'border-orange-500': task.priority == 'high',
                'border-red-500': task.priority == 'critical',
              }"
          >
            <div class="flex items-center px-4 py-4 sm:px-6">
              <div class="min-w-0 flex-1 flex items-center">
                <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                  <div class="col-span-2">
                    <p class="text-sm font-medium text-primary truncate">{{ task.dynamicName }}</p>
                    <p class="mt-2 flex items-center text-sm text-gray-500 truncate">
                      {{task.dynamicDesc}}
                    </p>
                  </div>
                </div>
              </div>
              <div class="flex items-center space-x-8">

                <PauseIcon
                  v-if="task._id == activeTask._id"
                  @click.stop="end_task()"
                  class="pulse-primary rounded-full h-8 w-8 hover:text-opacity-60 text-primary cursor-pointer"
                  aria-hidden="true"
                />
                <PlayIcon
                  v-else
                  @click.stop="start_task(task)"
                  class="h-8 w-8 text-gray-400 hover:text-primary cursor-pointer"
                  aria-hidden="true"
                />

                <ChevronRightIcon
                  class="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <EmptyState
      v-else
      class="mx-6 mt-6"
      text="No tasks yet!"
      buttonText="New task"
      @buttonClick="$emit('create_task')"
    />

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ChevronRightIcon, PlayIcon, PauseIcon } from '@heroicons/vue/outline';
import EmptyState from '@/components/EmptyState.vue';

export default {
  name: 'TasksList',
  components: {
    ChevronRightIcon,
    PlayIcon,
    PauseIcon,
    EmptyState,
  },
  computed: mapGetters({
    tasks: 'tasks/all',
    activeTask: 'tasks/active',
  }),
  methods: mapActions({
    start_task: 'tasks/start',
    end_task: 'tasks/end',
  }),
  async mounted() {
    if (!this.user) await this.me();
    // restrict access
    this.checkUserRoles('user');
  },
};
</script>
